import React, { Fragment, useContext, useState, useEffect } from "react";
import { pathOr, isEmpty, propOr, map, has } from "ramda";
import { useHistory, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { If, Else, Then } from "react-if";
import { Tab, Tabs, Modal } from "react-bootstrap";
import { errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";
import { Context } from "./Utility/store";
import { apiInstance } from "./Utility/axios";
import { seasonData } from "./Utility/SeasonData";
import { getFormattedLast10Games } from "./Utility/SeasonTrends";
import { getFormattedNextGame } from "./Utility/NextGame";
import { getFormattedSchedule } from "./Utility/FormatSchedule";
import { getFormattedTrends } from "./Utility/FormattedTrends";
import Chart from "./Utility/Chart";
import { GetTeamPlayerData, FormatNewsData } from "./Utility/actionMethod";
import { object } from "yup";
import {
  FacebookShareButton,
  TwitterShareButton,
  GmailShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
const PlayerProfile = () => {
  let selected_cat = localStorage.getItem("category_type").toLowerCase();

  let history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [loader, setLoader] = useState(true);
  const [Player1, setPlayer1] = useState([]);
  const [information, setInformation] = useState([]);
  const [standing, setStanding] = useState([]);
  const [Team, setTeam] = useState([]);
  const [Player1Team, setPlayer1Team] = useState([]);
  const [TweetData, setTweetData] = useState([]);
  const [News, setNews] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [ShowPlayerList, setShowPlayerList] = useState(false);
  const [seasonStats, setseasonStats] = useState([]);
  const [FormatedseasonStats, setFormatedseasonStats] = useState([]);
  const [seasonLast10, setseasonLast10] = useState([]);
  const [nextGameData, setnextGameData] = useState([]);
  const [NextGameDate, setNextGameDate] = useState([]);
  const [NextGameTime, setNextGameTime] = useState([]);
  const [SeasonScheduleData, setSeasonScheduleData] = useState([]);
  const [ChartData, setChartData] = useState([]);
  const [ProfileData, setProfileData] = useState([]);

  const [selectednflTeamPos, setselectednflTeamPos] = useState(
    selected_cat === "nfl"
      ? "passing"
      : selected_cat === "mlb"
      ? "hitting"
      : "nba"
  );
  const [season10TableHeader, setseason10TableHeader] = useState([]);
  const [season10data, setseason10data] = useState([]);
  const [season10dataLength, setseason10dataLength] = useState([]);
  const [table, setTable] = useState(false);

  const searchList = [...playerList, ...teamList];
  const [playerId, setplayerId] = useState([]);
  const [teamId, setteamId] = useState([]);

  const [teamconferenceInfo, setteamconferenceInfo] = useState([]);

  const [sharingURL, setsharingURL] = useState();
  const [ShareModal, setShareModal] = useState(false);
  const [isCopied, setIsCopied] = useState();

  const hitters = ["3B", "CF", "SS", "2B", "C", "1B", "RF", "LF", "DH", "OF"];
  const pitchers = ["P", "SP", "RP"];

  const HideShareModal = () => {
    setShareModal(false);
  };

  let isshare = localStorage.getItem("ishare");
  if (isshare === "true") {
    window.location.reload();
    localStorage.setItem("ishare", false);
  }

  const copyText = () => {
    setIsCopied("URL is copied");
    setTimeout(() => {
      setIsCopied("");
    }, 5000);
  };

  var isTeam = localStorage.getItem("isteam");
  let playerinfo = JSON.parse(localStorage.getItem("playerteamInfo"))[0];
  localStorage.setItem("twoPT", "");
  localStorage.setItem("chartData", "");
  localStorage.setItem("isReload", false);

  let id;
  let teamIds;
  let type;
  let position;
  let date;
  let getname;
  if (isTeam.toLowerCase() === "true") {
    type = "team";
  } else {
    type = "player";
  }

  useEffect(() => {
    id = propOr("", "id", playerinfo);

    teamIds = propOr("", "teamId", playerinfo);
    position = propOr("", "position", playerinfo);
    setTimeout(() => {
      getTweetData();
      getNews();
    }, 1000);
    setInformation(playerinfo);
    getSeasonStats(position);
    GetFormatLast10Games();
  }, []);

  useEffect(() => {
    if (state.teamPlayerSelected) {
      dispatch({ type: "TEAM_PLAYER_SELECTED", payload: false });
      // window.location.reload();
    }
  }, [state.teamPlayerSelected]);

  // GET PLAYER INFORMATION
  const getPlayerInformation = async () => {
    try {
      var response = await apiInstance.get(
        selected_cat + "/" + type + "/" + id + "/profile"
      );
      if (response) {
        if (isTeam === "true") {
          if (selected_cat === "nfl") {
            setteamconferenceInfo(response.data.response.conference);
          } else if (selected_cat === "mlb") {
            setteamconferenceInfo(response.data.response.league);
          } else {
            setteamconferenceInfo(response.data.response.conference);
          }
          setTeam(response.data.response.id);
          localStorage.setItem(
            "team1_info",
            JSON.stringify(response.data.response)
          );
          var standingResponse = await apiInstance.get(
            selected_cat + "/" + type + "/" + id + "/standing?season_type=REG"
          );
          if (standingResponse) {
            setStanding(standingResponse.data.response);
          }
        } else {
          setTeam(response.data.response.team.id);
        }
        let team =
          isTeam === "true"
            ? response.data.response
            : response.data.response.team;

        if (team !== undefined) {
          setPlayer1Team(team);
          localStorage.setItem("player1_team", JSON.stringify(team));
          position = response.data.response.position;
          setTimeout(() => {
            getSeasonStats(position);
            GetFormatLast10Games();
          }, 500);
        } else {
          setTimeout(() => {
            getSeasonStats(position);
            GetFormatLast10Games();
          }, 500);

          // dumySeasonStats();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    NextGame();
    getSeasonSechedule();
    getname = propOr("", "name", information);
    setTimeout(() => {
      getChartData(getname, propOr("", "position", information));
    }, 3000);
  }, [information]);

  useEffect(() => {
    NextGame();
    getSeasonSechedule();
  }, [Team]);

  // GET SEASON DATAS
  const getSeasonStats = async (position) => {
    try {
      const response =
        selected_cat === "mlb"
          ? await apiInstance.get(
              selected_cat +
                "/" +
                type +
                "/" +
                id +
                "/seasonal_stats?season_yr=2023&season_type=REG"
            )
          : await apiInstance.get(
              selected_cat +
                "/" +
                type +
                "/" +
                id +
                "/seasonal_stats?season_type=REG"
            );

      setseasonStats(seasonData(response, position, type, selected_cat));
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  // NEXT GAME
  const NextGame = async () => {
    if (type === "player") {
      id = propOr("", "teamId", playerinfo);
    }
    try {
      const response = await apiInstance.get(
        selected_cat + "/team/" + id + "/schedule/next_game"
      );
      setnextGameData(getFormattedNextGame(response, id, selected_cat));
      let schDate = getFormattedNextGame(
        response,
        id,
        selected_cat
      ).scheduledTime;

      const text = schDate;
      const dateTime = text.split(",");

      date = dateTime[0] + dateTime[1];
      setNextGameDate(date);
      setNextGameTime(dateTime[2]);
    } catch (err) {
      console.log(err);
    }
  };

  // SEASON SCHEDULE
  const getSeasonSechedule = async () => {
    var id =
      type === "team"
        ? propOr("", "id", playerinfo)
        : propOr("", "teamId", playerinfo);
    try {
      const response = await apiInstance.get(
        selected_cat + "/team/" + id + "/schedule/upcoming_games"
      );

      let SeasonSechedule = getFormattedSchedule(response, id, selected_cat);
      setSeasonScheduleData(SeasonSechedule);
    } catch (err) {
      console.log(err);
    }
  };

  // SEASON STATS
  const GetFormatLast10Games = async () => {
    let response = "";
    var teamId =
      type === "team"
        ? propOr("", "id", playerinfo)
        : propOr("", "teamId", playerinfo);

    try {
      if (selected_cat === "nfl") {
        response = await apiInstance.get(
          selected_cat +
            "/" +
            type +
            "/" +
            id +
            "/game_statistics?season_type=REG"
        );
      } else if (selected_cat === "mlb") {
        response = await apiInstance.get(
          selected_cat +
            "/" +
            type +
            "/" +
            id +
            "/game_summary?num_recent_games=10"
        );
      } else {
        response = await apiInstance.get(
          selected_cat + "/" + type + "/" + id + "/game_summary?season_type=REG"
        );
      }
      const season10res = getFormattedLast10Games(
        response,
        position,
        teamId,
        type,
        selected_cat
      );

      setseason10dataLength(Object.keys(season10res).length);
      setseasonLast10(season10res);
      setTimeout(() => {
        setTable(true);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setseason10data(seasonLast10);
    setseason10TableHeader(seasonLast10);
  }, [seasonLast10]);

  //CHART
  const getChartData = async (name, position) => {
    let response = "";
    var id = propOr("", "id", information);

    let playername = name;
    let playerPosition = position;
    if (id !== "") {
      try {
        if (selected_cat === "nfl") {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_statistics?season_type=REG"
          );
        } else if (selected_cat === "mlb") {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_summary?num_recent_games=10"
          );
        } else {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_summary?season_type=REG"
          );
        }
        if (response) {
          let chartResponse = getFormattedTrends(
            response,
            playerPosition,
            type,
            2,
            playername,
            selected_cat
          );

          var chartArr = [];
          if (type === "player") {
            if (selected_cat === "nfl") {
              const keysTreds = Object.keys(chartResponse.trends);
              chartResponse.dates.map((number, index) => {
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.trends,
                });
              });
              setProfileData(chartResponse.profileNameColor);
              var msg = { chartArr: chartArr };
              localStorage.setItem("onePT", JSON.stringify(msg));
              dispatch({ type: "CHARTDATA", payload: msg });
              setChartData(chartArr);
            }

            if (selected_cat === "nba") {
              chartResponse.dates.map((number, index) =>
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.trends,
                })
              );
              setProfileData(chartResponse.profileNameColor);
              var msg = { chartArr: chartArr };
              localStorage.setItem("onePT", JSON.stringify(msg));
              dispatch({ type: "CHARTDATA", payload: msg });
              setChartData(chartArr);
            }

            if (selected_cat === "mlb") {
              let chartArr = [];

              if (hitters.includes(playerPosition)) {
                chartResponse.dates.map((number, index) =>
                  chartArr.push({
                    DATE: number,
                    TRENDS: chartResponse.trends,
                  })
                );
                setProfileData(chartResponse.profileNameColor);
                var msg = { chartArr: chartArr };
                localStorage.setItem("onePT", JSON.stringify(msg));
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(chartArr);
              }
              if (pitchers.includes(playerPosition)) {
                chartResponse.dates.map((number, index) =>
                  chartArr.push({
                    DATE: number,
                    TRENDS: chartResponse.trends,
                  })
                );
                setProfileData(chartResponse.profileNameColor);
                var msg = { chartArr: chartArr };
                localStorage.setItem("onePT", JSON.stringify(msg));
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(chartArr);
              }
            }
          } else {
            if (selected_cat === "nfl") {
              if (chartResponse !== undefined) {
                var chartArrpass = [];
                chartResponse.passing.dates.map((number, index) =>
                  chartArrpass.push({
                    DATE: number,
                    TRENDS: chartResponse.passing.trends,
                  })
                );

                var chartArrrush = [];
                chartResponse.rushing.dates.map((number, index) =>
                  chartArrrush.push({
                    DATE: number,
                    TRENDS: chartResponse.rushing.trends,
                  })
                );

                var chartArrrecei = [];
                chartResponse.receiving.dates.map((number, index) =>
                  chartArrrecei.push({
                    DATE: number,
                    TRENDS: chartResponse.receiving.trends,
                  })
                );

                var chartArrDefense = [];
                chartResponse.defense.dates.map((number, index) =>
                  chartArrDefense.push({
                    DATE: number,
                    TRENDS: chartResponse.defense.trends,
                  })
                );

                var chartArrfield_goals = [];
                chartResponse.field_goals.dates.map((number, index) =>
                  chartArrfield_goals.push({
                    DATE: number,
                    TRENDS: chartResponse.field_goals.trends,
                  })
                );
                setProfileData(chartResponse.profileNameColor);
                var msg = {
                  passing: chartArrpass,
                  rushing: chartArrrush,
                  receiving: chartArrrecei,
                  defense: chartArrDefense,
                  field_goals: chartArrfield_goals,
                };
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(msg);
                localStorage.setItem("onePT", JSON.stringify(msg));
              }
            } else if (selected_cat === "mlb") {
              if (chartResponse !== undefined) {
                let hittingArr = [];
                let pitchingArr = [];

                chartResponse.hitting.dates.map((number, index) =>
                  hittingArr.push({
                    DATE: number,
                    TRENDS: chartResponse.hitting.trends,
                  })
                );

                chartResponse.pitching.dates.map((number, index) =>
                  pitchingArr.push({
                    DATE: number,
                    TRENDS: chartResponse.pitching.trends,
                  })
                );

                setProfileData(chartResponse.profileNameColor);
                var msg = { hitting: hittingArr, pitching: pitchingArr };
                localStorage.setItem("onePT", JSON.stringify(msg));
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(msg);
              }
            } else {
              chartResponse.nba.dates.map((number, index) =>
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.nba.trends,
                })
              );
              setProfileData(chartResponse.profileNameColor);
              var msg = { nba: chartArr };
              localStorage.setItem("onePT", JSON.stringify(msg));
              dispatch({ type: "CHARTDATA", payload: msg });
              setChartData(msg);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // GET TWEET INFORMATION
  const getTweetData = async () => {
    let id =
      type === "team"
        ? propOr("", "id", playerinfo)
        : propOr("", "teamId", playerinfo);

    try {
      const response = await apiInstance.get(
        selected_cat + "/" + type + "/" + id + "/twitter/tweets"
      );
      if (response) {
        setTweetData(response.data.response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // GET  PLAYER / TEAM  NEWS INFORMATION
  const getNews = async () => {
    // var selected_cat = (localStorage.getItem("category_type").toLowerCase());
    //http://api2.fanalyze.com:8080/v1/nfl/player/41c44740-d0f6-44ab-8347-3b5d515e5ecf/news
    //http://api2.fanalyze.com:8080/v1/nfl/player/4254d319-1bc7-4f81-b4ab-b5e6f3402b69/twitter/tweets
    // var team = JSON.parse(localStorage.getItem("player1_team")).id;
    try {
      const response = await apiInstance.get(
        selected_cat + "/" + type + "/" + propOr("", "id", playerinfo) + "/news"
      );
      if (response) {
        // const news = FormatNewsData(response.data.response,selected_cat);
        setNews(response.data.response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //  OPENING NEWS IN TABS INFORMATION
  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  const PlayerAgeCalc = () => {
    var dob =
      selected_cat === "nfl" ? information.birth_date : information.birthdate;
    var birthDate = new Date(dob);
    // get difference from current date;
    var difference = Date.now() - birthDate.getTime();
    var ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const parseDate = (input) => {
    var parts = input.split("-");
    var timeBits = parts[2].split(" ");

    var hms = timeBits[1].split(":");
    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(
      parts[0],
      parts[1] - 1,
      timeBits[0],
      hms[0],
      hms[1],
      hms[2]
    ); // Note: months are 0-based
  };
  // CALCULATE TIME

  const calcTime = (date) => {
    var dateTime = "" + date;
    var newDateTime = date.split("T");
    var NewsDate = newDateTime[0] + " " + newDateTime[1];
    var conNewsDate = parseDate(NewsDate);
    // const NewsTime =  newDateTime[1];
    var today = new Date();

    var diffMs = conNewsDate - today; // milliseconds between now & dateTime
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours

    //var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    if (today === NewsDate.split(" ")[0]) {
      return `${Math.abs(diffHrs)} Hours ago`;
    } else if (Math.abs(diffDays) > 30 && Math.abs(diffDays) < 45) {
      return "Month ago";
    } else if (Math.abs(diffDays) > 45) {
      return "Months ago";
    } else {
      return `${Math.abs(diffDays)} Days ago`;
    }
  };

  // REMOVE PLAYER
  const RemovePalyerHandler = () => {
    localStorage.setItem("player_1", "");
    localStorage.setItem("player1_team", "");
    history.push("/");
  };

  // GET PLAYER LIST
  const getPlayerList = async (player) => {
    // var selected_cat = (localStorage.getItem("category_type").toLowerCase());
    try {
      const response = await apiInstance.get(
        selected_cat + "/players/list?prefix=" + player
      );
      if (response) {
        setPlayerList(response.data.response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // GET TEAM LIST
  const getTeamList = async (teamVal) => {
    var categoryType = localStorage.getItem("category_type").toLowerCase();
    try {
      const response = await apiInstance.get(
        categoryType + "/teams/list?prefix=" + teamVal
      );
      if (response) {
        setTeamList(response.data.response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // ======= GET TEAM =======
  const getPlayerHanlder = (e) => {
    if (isTeam !== "true") {
      getPlayerList(e.target.value);
    }
    if (isTeam === "true") {
      getTeamList(e.target.value);
    }
    if (e.target.value.length > 3) {
      setShowPlayerList(true);
    } else {
      setShowPlayerList(false);
    }
  };

  // GET SINGLE PLAYER HANDLER
  const singlePlayerHandler = async (compare) => {
    if (information.id === propOr("", "id", compare)) {
      if (isTeam === "true") {
        errorToast("Team Already Selected");
      } else {
        errorToast("Player Already Selected");
      }
    } else {
      let id = propOr("", "id", compare);
      await GetTeamPlayerData(
        dispatch,
        selected_cat.toLowerCase(),
        type,
        id,
        state
      );
      history.push("/player-comparision");
    }
  };

  const shareUrlHandler = () => {
    let api = "http://www.fanalyze.com/profilesharing/";
    if (type === "player") {
      api +=
        propOr("0", "id", playerinfo) +
        "/" +
        propOr("0", "name", playerinfo) +
        "/" +
        propOr("0", "position", playerinfo) +
        "/";
    } else {
      api +=
        propOr("0", "id", playerinfo) +
        "/" +
        propOr("0", "name", playerinfo) +
        "/" +
        0 +
        "/";
    }
    api += selected_cat + "/" + type;
    api = api.split(" ").join("");
    setsharingURL(api);
  };

  const Image = (props) => {
    const [imageError, setImageError] = useState(false);
    return (
      <img
        className="slick-slide-image"
        src={
          props.img === "" || props.img === undefined
            ? "assets/images/dummy.png"
            : imageError
            ? "assets/images/dummy.png"
            : props.img
        }
        onError={(e) => setImageError(true)}
      />
    );
  };

  return (
    <Fragment>
      <div className={loader ? "sipnner-wrap" : "d-none"}>
        <Spinner animation="border" />
      </div>

      {/*<!--=== BREADCRUMB ==== -->*/}
      <section className="brd-cmb  p-100">
        <div className="container">
          <div className="breadcrumb-wrapper">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="./">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Search to compare
                </li>
              </ol>
            </nav>
            <div className="search-button-wrapper">
              <div className="search-wrapper">
                <div className="player-search-box">
                  <span>
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    name=""
                    placeholder="Search to Compare"
                    onChange={getPlayerHanlder}
                  />
                  <div className="sreached-player-block">
                    <span onClick={RemovePalyerHandler}>
                      {isTeam === "true"
                        ? propOr("", "teamMarket", information)
                        : propOr("", "name", information).split(" ")[
                            propOr("", "name", information).split(" ").length -
                              1
                          ]}
                      <i class="far fa-times-circle"></i>
                    </span>

                    {/*  
                  <span onClick={RemovePalyerHandler}>
                      {isTeam === "true" ?
                       information.market
                      :  
                      selected_cat === "nfl"
                        ? information.name
                        : information.full_name
                    }
                    <i class="far fa-times-circle"></i>
                    </span>
                    */}
                  </div>

                  <If condition={ShowPlayerList}>
                    <ul
                      className={
                        playerList.length === 0
                          ? "search-result h-auto"
                          : "search-result h-400"
                      }
                    >
                      <div className={loader ? "sipnner-wrap" : "d-none"}>
                        <Spinner animation="border" />
                      </div>
                      {searchList.length !== 0 ? (
                        searchList.map((searchitem, index) => (
                          <div>
                            {
                              <li
                                onClick={() => singlePlayerHandler(searchitem)}
                                key={index}
                              >
                                {" "}
                                <span className="searchListImage">
                                  {" "}
                                  <Image
                                    img={
                                      searchitem.photo_url !== undefined
                                        ? searchitem.photo_url
                                        : searchitem.logo_url
                                    }
                                  />
                                </span>{" "}
                                {searchitem.photo_url
                                  ? (selected_cat === "nfl"
                                      ? searchitem.name.length
                                      : searchitem.full_name.length) === ""
                                    ? "No Such Player or Team Found"
                                    : selected_cat === "nfl"
                                    ? searchitem.name
                                    : searchitem.full_name
                                  : searchitem.market_name}
                              </li>
                            }
                          </div>
                        ))
                      ) : isTeam === "true" ? (
                        <li>No such Team Found </li>
                      ) : (
                        <li>No such Player Found </li>
                      )}
                    </ul>
                  </If>
                </div>
              </div>
              <div className="compare-btn-wrap">
                <button
                  onClick={() => {
                    setShareModal(true);
                    shareUrlHandler();
                  }}
                >
                  <img src="assets/images/icons/share.png" alt="compare" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="common-section-lg-2   ">
        <div className={loader ? "sipnner-wrap" : "d-none"}>
          <Spinner animation="border" />
        </div>

        <section className="player-detail-status">
          <div className="container">
            <div className="cmn-shadow-box">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <If condition={isTeam === "true"}>
                    <Then>
                      <div className="player-detail-wrap d-flex align-items-center">
                        <div className="player-image-wrap">
                          <img
                            src="./assets/images/design.png"
                            className="design"
                            alt=""
                          />
                          <img
                            src={propOr("", "profileImageUrl", information)}
                            className="player-img flag-img"
                            alt=""
                          />
                        </div>
                        <div className="player-wrap">
                          <h3 className="title_2 text-left">
                            {propOr("", "teamMarket", information) +
                              " " +
                              propOr("", "teamName", information)}
                          </h3>

                          <h4>{propOr("", "leagueName", information)} </h4>

                          <div className="player-detail-age d-flex">
                            <p>
                              {propOr("", "win", information)}-
                              {propOr("", "loss", information)}{" "}
                            </p>

                            <p>
                              {propOr("", "divisionName", information) ===
                              "Conf Rank"
                                ? propOr("", "divisionName", information) +
                                  " " +
                                  propOr("", "rank", information)
                                : propOr("", "rank", information) +
                                  " in " +
                                  propOr("", "divisionName", information)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Then>
                    <Else>
                      <div className="player-detail-wrap d-flex align-items-center">
                        <div className="player-image-wrap">
                          <img
                            src="./assets/images/design.png"
                            className="design"
                            alt=""
                          />
                          <img
                            src={propOr("", "profileImageUrl", information)}
                            className="player-img"
                            alt=""
                          />
                        </div>
                        <div className="player-wrap">
                          <h3 className="title_2 text-left">
                            {propOr("", "name", information)} #
                            {propOr("", "jerseyNumber", information)}
                          </h3>

                          <h4>
                            {propOr("", "teamMarket", information) +
                              " " +
                              propOr("", "teamName", information)}
                          </h4>
                          <div className="player-detail-age d-flex">
                            <p>
                              {" "}
                              {propOr("", "position", information)}{" "}
                              {selected_cat === "mlb"
                                ? propOr(undefined, "batHand", information) !==
                                  undefined
                                  ? " | B/T : " +
                                    propOr("", "batHand", information) +
                                    "/" +
                                    propOr("", "throwHand", information)
                                  : ""
                                : ""}
                            </p>

                            <p>Age: {propOr("", "age", information)}</p>
                          </div>
                          <div
                            className={
                              propOr("", "status", information) === "Active"
                                ? "active-div active"
                                : "active-div"
                            }
                          ></div>
                        </div>
                      </div>
                    </Else>
                  </If>
                </div>
                <div className="col-lg-5">
                  <div className="season-status">
                    <h4>season stats</h4>

                    <div className="d-flex">
                      <div className="status status1">
                        <h6>{!loader ? seasonStats[0].value : ""}</h6>
                        <p>{!loader ? seasonStats[0].name : ""}</p>
                      </div>
                      <div className="status status1">
                        <h6>{!loader ? seasonStats[1].value : ""}</h6>
                        <p>{!loader ? seasonStats[1].name : ""}</p>
                      </div>
                      <div className="status status1">
                        <h6>{!loader ? seasonStats[2].value : ""}</h6>
                        <p>{!loader ? seasonStats[2].name : ""}</p>
                      </div>
                      <div className="status status1">
                        <h6>{!loader ? seasonStats[3].value : ""}</h6>
                        <p>{!loader ? seasonStats[3].name : ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="news profile-news pt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="news-wrap cmn-shadow-box h-400">
                  <div className="title_2 d-flex align-items-center justify-content-between">
                    <h3>News</h3>
                    <a href="#" className="see_all">
                      see all
                    </a>
                  </div>
                  <div className="news_container">
                    {News.map((news, index) => (
                      <span
                        onClick={() => {
                          openUrl(news.url);
                        }}
                        key={index}
                        className="news-tab"
                      >
                        <div className="new-from news-form-2 d-flex justify-content-between">
                          <div>
                            <img
                              src={
                                selected_cat === "mlb"
                                  ? news.source.img_url
                                  : propOr("", "img_url", news)
                              }
                              alt="-"
                            />
                            <h6>
                              {selected_cat === "mlb"
                                ? news.source.name.split(".")[1]
                                : propOr("", "domain", news).split(".")[1]}
                            </h6>
                          </div>
                          <p>
                            <span>
                              <i className="far fa-clock"></i>
                            </span>{" "}
                            {selected_cat === "nfl" || selected_cat === "nba"
                              ? calcTime(propOr("", "last_updated", news))
                              : calcTime(propOr("", "updated_utc", news))}
                          </p>
                        </div>
                        <div className="new-head-container">
                          <div className="new-head-img">
                            <img src={propOr("", "img_url", news)} alt="-" />
                          </div>
                          <div className="headline-container">
                            <p></p>
                            <h5>
                              {propOr("", "title", news).length > 100
                                ? propOr("", "title", news).substr(0, 100 - 1) +
                                  "..."
                                : propOr("", "title", news)}
                            </h5>
                          </div>
                        </div>

                        <p>
                          {selected_cat === "nfl" || selected_cat === "nba"
                            ? propOr("", "meta_description", news).length > 100
                              ? propOr("", "meta_description", news).substr(
                                  0,
                                  100 - 1
                                ) + "..."
                              : propOr("", "meta_description", news)
                            : propOr("", "desc", news)}
                        </p>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="next-game-compare cmn-shadow-box h-400">
                  <div className="title_2 w-100 d-flex align-items-center justify-content-between">
                    <h3 className="mb-0">next game</h3>
                    {/*
                    <a href="#" className="common-btn odds_btn">
                      odds
                    </a>
                    */}
                  </div>
                  {NextGameDate !== "Invalid dateundefined" ? (
                    nextGameData.length === undefined ? (
                      <div className="team-outter-wrapper">
                        <div className="teams-wrap">
                          {}
                          <div className="team">
                            <div className="team-flag-img">
                              <img src={nextGameData.home.logo_url} alt="" />
                            </div>
                            <h4 className="title_2">
                              {nextGameData.home.name}
                            </h4>
                          </div>
                          <div>
                            <h5>@</h5>
                          </div>
                          <div className="team">
                            <div className="team-flag-img">
                              <img src={nextGameData.away.logo_url} alt="" />
                            </div>
                            <h4 className="title_2">
                              {nextGameData.away.name}
                            </h4>
                          </div>
                        </div>
                        <div className="next-game-detail">
                          <div>
                            <span>
                              <i className="fas fa-calendar-day"></i>
                            </span>{" "}
                            {NextGameDate}
                          </div>
                          <div>
                            <span>
                              <i className="far fa-clock"></i>
                            </span>{" "}
                            {NextGameTime}
                          </div>
                          <div>
                            <span>
                              <i className="fas fa-map-marker-alt"></i>
                            </span>{" "}
                            {nextGameData.venueCity} {nextGameData.venueName}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    "No Upcoming Games"
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*
        <section className="season-status p-50">
          <div className="container">
            <div className="season-table-wrapper">
              <h2 className="title_2">Season Schedule</h2>
              <table className="table table-bordered table-striped season-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>opponent</th>
                    <th>Home or Away</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    SeasonScheduleData.length !== 0 ?
                      SeasonScheduleData.map((data, index) => (
                    <tr key={index}>{data.map((tdData)=>(<td>{tdData}</td>))}</tr>
                  ))
                  :
                  <tr><td colSpan={4}>No Season Schedule</td></tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </section>
        */}

        {/*
      <section className="news ranking-table-wrap">
          <div className="container">
              <div className="row">
              <div class="cmn-shadow-box exclusive-data h-520">
                <div class="title_2 w-100 d-flex align-items-center justify-content-between">
                    <h3 class="mb-0">exclusive Data</h3>
                    { selected_cat === "nfl" && type ?
                    <ul className="mb-3 nav nav-tabs" role="tablist">
                      <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="passing"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("passing")} >QBR</button></li>
                      <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="rushing"?"nav-link active ":"nav-link"} onClick={()=>setselectednflTeamPos("rushing")}>TDS</button></li>
                      <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="receiving"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("receiving")} >Rec</button></li>
                   </ul>
                    :""}
                    { selected_cat === "mlb" && type ?
                    <ul className="mb-3 nav nav-tabs" role="tablist">
                    <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="hitting"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("hitting")} >hitter</button></li>
                    <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="pitching"?"nav-link active ":"nav-link"} onClick={()=>setselectednflTeamPos("pitching")}>pitcher</button></li>
                    <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="pitching"?"nav-link active ":"nav-link"} onClick={()=>setselectednflTeamPos("pitching")}>pitcher</button></li>
                    </ul>
                    :""}
                </div>
              </div>
              </div>
          </div>
      </section>  
        */}

        <section className="news p-50">
          <div className="container">
            <div className="row">
              <div
                className={selected_cat !== "mlb" ? "col-lg-7" : "col-lg-12"}
              >
                <div className="news-wrap cmn-shadow-box h-630">
                  {selected_cat === "nfl" && type === "team" ? (
                    <div className="ranking-table-wrap">
                      <ul className="mb-3 nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "passing"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("passing")}
                          >
                            Pass
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "rushing"
                                ? "nav-link active "
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("rushing")}
                          >
                            Rush
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "receiving"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("receiving")}
                          >
                            Rec
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "defense"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("defense")}
                          >
                            Def
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "field_goals"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("field_goals")}
                          >
                            Kick
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : selected_cat === "mlb" && type === "team" ? (
                    <div className="ranking-table-wrap">
                      <ul className="mb-3 nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "hitting"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("hitting")}
                          >
                            hitter
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className={
                              selectednflTeamPos === "pitching"
                                ? "nav-link active "
                                : "nav-link"
                            }
                            onClick={() => setselectednflTeamPos("pitching")}
                          >
                            pitcher
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="h-480">
                    <Chart
                      selectedType={type}
                      selectedGame={selected_cat}
                      data={ChartData}
                      selectedPos={selectednflTeamPos}
                    ></Chart>
                  </div>
                </div>
              </div>

              {selected_cat !== "mlb" && (
                <div className="col-lg-5">
                  <div class="cmn-shadow-box exclusive-data h-630">
                    <div class="title_2 w-100 d-flex align-items-center justify-content-between mt-0 ">
                      <h3 class="mb-0"> Season schedule </h3>
                      <a href="#" class="see_all">
                        see all
                      </a>
                    </div>
                    <div className="season-table-wrapper h-season-tab">
                      <table
                        className="table table-bordered table-striped season-table"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            {(selected_cat === "nfl" ||
                              selected_cat === "nfl") && <th>Week</th>}

                            <th>Date</th>
                            <th>opponent</th>
                            <th>Home or Away</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {SeasonScheduleData.length !== 0 ? (
                            SeasonScheduleData.map((data, index) => (
                              <tr key={index}>
                                {data.map((tdData) => (
                                  <td style={{ width: "20%" }}>{tdData}</td>
                                ))}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>No Season Schedule</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section
          className={
            type === "team"
              ? "news seasonStatus table-top pb-5"
              : "news seasonStatus pb-5"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div class="cmn-shadow-box exclusive-data h-520">
                  <div class="title_2 w-100 d-flex align-items-center justify-content-between">
                    <h3 class="mb-0">Season Stats</h3>
                  </div>

                  <If condition={selected_cat === "nfl"}>
                    <Then>
                      <div className="season-table-wrapper">
                        {isTeam === "true" ? (
                          <Tabs
                            defaultActiveKey="pass"
                            id="uncontrolled-tab-example"
                            className="mb-3 exclusive_data_tab_pills  "
                          >
                            {season10data.passing !== undefined ? (
                              <Tab eventKey="pass" title="pass">
                                <table
                                  className="table table-striped  season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.passing.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.passing.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}
                            {season10data.rushing !== undefined ? (
                              <Tab eventKey="rush" title="rush">
                                <table
                                  className="table table-bordered table-striped season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.rushing.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.rushing.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}

                            {season10data.receiving !== undefined ? (
                              <Tab eventKey="rec" title="rec">
                                <table
                                  className="table table-bordered table-striped season-table "
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.receiving.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.receiving.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}

                            {season10data.defense !== undefined ? (
                              <Tab eventKey="Def" title="Def">
                                <table
                                  className="table table-bordered table-striped season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.defense.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.defense.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}

                            {season10data.field_goals !== undefined ? (
                              <Tab eventKey="Kick" title="Kick">
                                <table
                                  className="table table-bordered table-striped season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.field_goals.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.field_goals.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}
                          </Tabs>
                        ) : (
                          <table
                            className="table table-bordered table-striped season-table"
                            style={{ width: "220%" }}
                          >
                            <thead>
                              <tr>
                                {season10data.statsHeader !== undefined
                                  ? season10data.statsHeader.map((header) => (
                                      <th style={{ width: "12%" }}>{header}</th>
                                    ))
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {season10data.statsData !== undefined
                                ? season10data.statsData.map((data, index) => (
                                    <tr key={index}>
                                      {data.map((tdData, index) => (
                                        <td style={{ width: "12%" }}>
                                          {tdData}
                                        </td>
                                      ))}
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Then>
                  </If>
                  <If condition={selected_cat === "mlb"}>
                    <Then>
                      <div className="season-table-wrapper">
                        {isTeam === "true" ? (
                          <Tabs
                            defaultActiveKey="Hitter"
                            id="uncontrolled-tab-example"
                            className="mb-3 exclusive_data_tab_pills  "
                          >
                            {season10data.hitting !== undefined ? (
                              <Tab eventKey="Hitter" title="Hitter">
                                <table
                                  className="table table-bordered table-striped season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.hitting.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.hitting.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}
                            {season10data.pitching !== undefined ? (
                              <Tab eventKey="Pitcher" title="Pitcher">
                                <table
                                  className="table table-bordered table-striped season-table"
                                  style={{ width: "220%" }}
                                >
                                  <thead>
                                    <tr>
                                      {season10data.pitching.statsHeader.map(
                                        (header, index) => (
                                          <th
                                            style={{ width: "12%" }}
                                            key={index}
                                          >
                                            {header}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {season10data.pitching.statsData.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          {data.map((tdData) => (
                                            <td style={{ width: "12%" }}>
                                              {tdData}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Tab>
                            ) : (
                              ""
                            )}
                          </Tabs>
                        ) : (
                          <table
                            className="table table-bordered table-striped season-table"
                            style={{ width: "220%" }}
                          >
                            <thead>
                              <tr>
                                {season10data.statsHeader !== undefined
                                  ? season10data.statsHeader.map((header) => (
                                      <th style={{ width: "12%" }}>{header}</th>
                                    ))
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {season10data.statsData !== undefined
                                ? season10data.statsData.map((data, index) => (
                                    <tr key={index}>
                                      {data.map((tdData) => (
                                        <td style={{ width: "12%" }}>
                                          {tdData}
                                        </td>
                                      ))}
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Then>
                  </If>
                  {selected_cat === "nba" ? (
                    type === "team" ? (
                      <div className="season-table-wrapper">
                        {season10data.nba !== undefined ? (
                          <table
                            className="table table-bordered table-striped season-table"
                            style={{ width: "220%" }}
                          >
                            <thead>
                              <tr>
                                {season10data.nba.statsHeader !== undefined
                                  ? season10data.nba.statsHeader.map(
                                      (header) => (
                                        <th style={{ width: "12%" }}>
                                          {header}
                                        </th>
                                      )
                                    )
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {season10data.nba.statsData !== undefined
                                ? season10data.nba.statsData.map(
                                    (data, index) => (
                                      <tr key={index}>
                                        {data.map((tdData) => (
                                          <td style={{ width: "12%" }}>
                                            {tdData}
                                          </td>
                                        ))}
                                      </tr>
                                    )
                                  )
                                : ""}
                            </tbody>
                          </table>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="season-table-wrapper">
                        {season10data !== undefined ? (
                          <table
                            className="table table-bordered table-striped season-table"
                            style={{ width: "220%" }}
                          >
                            <thead>
                              <tr>
                                {season10data.statsHeader !== undefined
                                  ? season10data.statsHeader.map((header) => (
                                      <th style={{ width: "12%" }}>{header}</th>
                                    ))
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {season10data.statsData !== undefined
                                ? season10data.statsData.map((data, index) => (
                                    <tr key={index}>
                                      {data.map((tdData) => (
                                        <td style={{ width: "12%" }}>
                                          {tdData}
                                        </td>
                                      ))}
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="next-game-compare cmn-shadow-box h-520">
                  <div className="title_2 w-100 d-flex align-items-center justify-content-between">
                    <h3 className="mb-0">Twitter Feeds</h3>
                    <a href="#" className="see_all">
                      see all
                    </a>
                  </div>

                  <div className="news_container tweet-container">
                    {TweetData.length === 0 ? (
                      <p>No Tweet Yet</p>
                    ) : (
                      TweetData.map((tweets) => (
                        <a href="#" className="news-tab">
                          <div className="tweet-header">
                            <div className="tweet-img-container">
                              <div className="tweet-img">
                                <img src={tweets.profile_image_url} alt="" />
                              </div>
                              <h5 className="title_2">{tweets.screen_name}</h5>
                            </div>
                            <div className="tweet-time">
                              <p>a day ago</p>
                            </div>
                          </div>
                          <p>{tweets.text}</p>
                        </a>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <ToastContainer autoClose={2000} />
      <Modal
        show={ShareModal}
        onHide={HideShareModal}
        size="lg"
        centered
        keyboard={false}
        className="share-modal"
      >
        <Modal.Body>
          <div className="login-detail">
            <h5>Generated URL can be used for sharing Player OR Team detail</h5>
            <div className="share-icons">
              <FacebookShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={50} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={50} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
              <EmailShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={50} round />
              </EmailShareButton>
              <button className="copy-link">
                <span
                  onClick={() => {
                    copyText();
                    navigator.clipboard.writeText(sharingURL);
                  }}
                >
                  <img src="assets/images/copy.png" />
                </span>
              </button>
            </div>
            <span className="iscopied">{isCopied}</span>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default PlayerProfile;
