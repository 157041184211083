import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { successToast, errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import { Context } from "./Utility/store";
import { Spinner } from "react-bootstrap";




import {
  CardElement,
  Elements,
  useStripe,
  useElements,

} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";




const CheckoutForm = ({ plan_id, subAmt, subPlan }) => {
  let history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState(
    {
      name: "",
      email: ""
    }
  )
  const [errors, setErrors] = useState({

  });


  const formHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const ValiditState = () => {
    let error = {};
    if (state.name.trim() === "") {
      error.name = "Please enter Name";
    }
    if (state.email.trim() === "") {
      error.email = "Please enter Email";
    }
    return error;
  }



  const createSubscription = async () => {
    setErrors(ValiditState());
    if (Object.keys(errors).length === 0) {
      try {
        const paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement("card"),
          billing_details: { name: state.name, email: state.email },
        });


        var id = paymentMethod.paymentMethod.id;
        const Paydetail = {
          subscriptiontype: plan_id,
          tokenId: id,
        };
        pay(Paydetail);
      } catch (error) {
        console.log(error);
      }
    }
    else {
      return;

    }
  };


  const pay = async (paymentDetail) => {
    let sendheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      setLoader(true);
      const response = await axios.post(
        request.SUBCRIPTION,
        {
          subscriptiontype: paymentDetail.subscriptiontype,
          tokenId: paymentDetail.tokenId,
        },
        sendheaders
      );
      // console.log({response})
      if (response) {
        setLoader(false);


        // var details = {
        //   name : response.data.name,
        //   email : response.data.email,
        //   clientSecret : response.data.clientSecret
        // }


        confirmCardHandler(response.data, paymentDetail.tokenId, paymentDetail)

        // history.push("/");  
        // getUser();
      } else {
        const message = response.data.message;
        errorToast(message);
      }
    } catch (err) {
      if (!err.respsonse) {
        setLoader(false);
        errorToast(err.message);
      }
    }
  };

  const confirmCardHandler = async (data, id, paymentDetail) => {

    try {
      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: id
      });

      if (result.error) {
        console.error(result.error.message);
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        // Payment succeeded, handle the success
        retriveSubscription(data, id, paymentDetail)
      }
    } catch (error) {
      console.error(error);
    }
  };


  const retriveSubscription = async (data, id, paymentDetail) => {

    stripe.retrievePaymentIntent(data.clientSecret).then(({ paymentIntent }) => {
      const message = document.querySelector('#message')

      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case 'succeeded':
          //console.log("message","Success! Payment received")  
          stripeSuccess(data.subscriptionId, paymentDetail.subscriptiontype)
          //message.innerText = 'Success! Payment received.';
          break;

        case 'processing':
          errorToast("message", "Payment processing. We'll update you when payment is received.")
          // message.innerText = "Payment processing. We'll update you when payment is received.";
          break;

        case 'requires_payment_method':
          errorToast("message", 'Payment failed. Please try another payment method.')
          // message.innerText = 'Payment failed. Please try another payment method.';
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          break;

        default:
          message.innerText = 'Something went wrong.';
          break;
      }
    });


  }

  const stripeSuccess = async (subscriptionId, subscriptiontype) => {

    let sendheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      setLoader(true);
      const response = await axios.post(
        request.SUBSCRIPTION_SUCCESS,
        {
          subscriptionId,
          subscriptiontype,
        },
        sendheaders
      );
      // console.log({response})
      if (response) {
        setLoader(false);
        successToast(response.data.message)
        history.push("/");
        // getUser();
      }
    } catch (err) {
      if (!err.respsonse) {
        setLoader(false);
        errorToast(err.message);
      }
    }
  };



  return (
    <div className="sample">
      <div className={loader ? "sipnner-wrap" : "d-none"}>
        <Spinner animation="border" />
      </div>
      <h4 className="text-center">{subPlan}</h4>

      <input
        type="text"
        placeholder="Enter Name"
        name="name"
        value={state.name}
        onChange={formHandle}
      />
      <span>{errors?.name}</span>
      <input
        type="email"
        placeholder="Enter Email"
        name="email"
        value={state.email}
        onChange={formHandle}
      />
      <span>{errors?.email}</span>
      <div className="sample-wrap">
        <CardElement />
      </div>
      <br />
      <button onClick={createSubscription}>
        {loader ? "loading..." : `Pay $ ${subAmt}`}
      </button>
    </div>
  );
};


const Pricing = () => {
  const [payment, setpayment] = useState(false);
  const [plan_id, setPlan_id] = useState([]);
  const [subPlan, setsubPlan] = useState();
  const [subAmt, setsubAmt] = useState();
  const [chkLogin, setchkLogin] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [draftCheatHide, setdraftCheatHide] = useState(true);
  const [paymentDetail, setPaymentDetail] = useState({});
  let history = useHistory();

  // useEffect(() => {

  //   let date1 = new Date();
  //   const date2 = new Date("september 26, 2023");
  //   if (date1 === date2) {
  //     setdraftCheatHide(true);
  //   } else if (date1 > date2) {
  //     setdraftCheatHide(true);
  //   }
  //   else {
  //     setdraftCheatHide(false)
  //   }

  // }, []);


  const stripePromise = loadStripe("pk_live_hlYVqbW5NS2J6wefkRzDGkKy");
  // const stripePromise = loadStripe("pk_test_J8vcCdew12TjN2N0tIUNdHRu")


  const paymentClose = () => {
    setpayment(false);
  };
  const Handlepayment = (Plan_id, subAmt, Plan) => {

    setPaymentDetail({
      Plan_id,
      subAmt,
      Plan
    })



    if (chkLogin) {
      setsubPlan(Plan);
      setsubAmt(subAmt);
      setPlan_id(Plan_id);
      setpayment(true);
    } else {
      //dispatch({ type: "OPEN_LOGIN_MODAL", payload: true });
    }
  };

  const checkLogin = (id) => {
    if (chkLogin) {
      history.push({
        pathname: '/payment',
        state: { id },
      })
    } else {
      history.push("/login")
      //dispatch({ type: "OPEN_LOGIN_MODAL", payload: true });
    }
  }





  useEffect(() => {
    let user = localStorage.getItem("userDetail");
    if (user === "" || user === null || user === undefined || user === "null") {
      setchkLogin(false);
    } else {
      setchkLogin(true);
    }

  }, [])



  return (
    <Fragment>
      {/*======= FANTASY SPORTS ======== */}
      <section className="fantasy_sports p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title_2 pb-5">
                {" "}
                Get Advanced Data & Predictive Analytics{" "}
              </h2>
            </div>
          </div>
        </div>
      </section>
      {draftCheatHide && (
        <section className="pricing pb-50">
          <div className="container">
            <h2 className="title_2 mb-5">
              Fantasy Football Draft Cheat Sheet and Rankings
            </h2>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="pricing-wrap">
                  <h2>
                    <span>$</span>14<sup>.99</sup>
                  </h2>
                  <h6>Fantasy Football 2023 Season </h6>


                  <div className={`main-pricing-content`}>
                    <ul>
                      <li>
                        <p>
                          Aggregated player draft rankings from top fantasy
                          football websites
                        </p>
                      </li>
                      <li>
                        <p>Easily manage your draft</p>
                      </li>
                      <li>
                        <p>Compare players draft rankings</p>
                      </li>
                      <li>
                        <p>Filter by position and find players quickly</p>
                      </li>
                    </ul>
                    {/* <button
                      className="common-btn"
                      onClick={() => {

                        Handlepayment("5", "14.99", "Draft Cheat Sheet");

                      }}
                    >
                      Buy
                    </button> */}
                    {/* 
                    <button  className="common-btn" 
                       onClick={()=>history.push({
                       pathname: '/payment',
                       state: { id: "5" },
                    })
                  }
                    
                    >Buy</button>
                   */}

                    {/* <button
                  onClick={()=>{
                    checkLogin("5")
                  }}
                  >
                    Buy
                  </button> */}


                    <button className="common-btn"
                      onClick={() => {
                        checkLogin("5")
                      }}
                    >
                      Buy
                    </button>



                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="pricing pb-50">
        <div className="container">
          <h2 className="title_2 mb-5"> Fantasy Sports Only </h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="pricing-wrap">
                <h2>
                  <span>$</span>53<sup>.99</sup>
                </h2>
                <h6>Yearly </h6>

                {/*
                <div className="main-pricing-content">
                  <p>Save 50%</p>
                  <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment("2", "53.99", "Fantasy Sports Yearly");
                    }}
                  >
                    Buy
                  </button>
                </div>
                */}
                <div className="main-pricing-content">
                  <p class="discoutn-text">Save 50%</p>
                  <ul>
                    <li>
                      <p>Advanced Player Data</p>
                    </li>
                    <li>
                      <p>Customized Player Analysis</p>
                    </li>

                    <li>
                      <p>
                        Aggregated weekly , Ranking , Projections , Waiver Wire
                        And Start or Sit{" "}
                      </p>
                    </li>
                    <li>
                      <p>Player Historical Data</p>
                    </li>
                    <li>
                      <p className="del"> Customized Team Analysis </p>
                    </li>
                    <li>
                      <p className="del">Advanced Team Data</p>
                    </li>
                    <li>
                      <p className="del">Team Historical Data</p>
                    </li>
                  </ul>
                  {/* <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment("2", "53.99", "Fantasy Sports Yearly");
                    }}
                  >
                    Buy
                  </button> */}
                  {/* <button  className="common-btn" 
                  onClick={()=>history.push({
                  pathname: '/payment',
                  state: { id: "2" },
                  })}
                  onClick={()=>checkLogin()}
                
                >Buy</button> */}


                  <button className="common-btn"
                    onClick={() => {
                      checkLogin("2")
                    }}
                  >
                    Buy
                  </button>


                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="pricing-wrap">
                <h2>
                  <span>$</span>8<sup>.99</sup>
                </h2>
                <h6>MONTHLY </h6>
                {/*
                <div className="main-pricing-content">
                  <p></p>
                  <button
                    className="common-btn"
                    onClick={() => {
                      //ChkLoginHandler();
                      Handlepayment("1", "8.99", "Fantasy Sports Monthly");
                    }}
                  >
                    Buy
                  </button>
                </div>
                */}

                <div className="main-pricing-content">
                  <p class="discoutn-text"></p>
                  <ul>
                    <li>
                      <p>Advanced Player Data</p>
                    </li>
                    <li>
                      <p>Customized Player Analysis</p>
                    </li>
                    <li>
                      <p>
                        Aggregated weekly , Ranking , Projections , Waiver Wire
                        And Start or Sit{" "}
                      </p>
                    </li>
                    <li>
                      <p>Player Historical Data</p>
                    </li>
                    <li>
                      <p className="del"> Customized Team Analysis </p>
                    </li>
                    <li>
                      <p className="del">Advanced Team Data</p>
                    </li>
                    <li>
                      <p className="del">Team Historical Data</p>
                    </li>
                  </ul>
                  {/* <button
                    className="common-btn"
                    onClick={() => {
                      //ChkLoginHandler();
                      Handlepayment("1", "8.99", "Fantasy Sports Monthly");
                    }}
                  >
                    Buy
                  </button> */}
                  {/* <button  className="common-btn" 
                       onClick={()=>history.push({
                       pathname: '/payment',
                       state: { id: "1" },
                    })}>Buy</button> */}


                  <button className="common-btn"
                    onClick={() => {
                      checkLogin("1")
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing pb-50">
        <div className="container">
          <h2 className="title_2 mb-5"> Fantasy Sports And Betting </h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="pricing-wrap">
                <h2>
                  <span>$</span>174<sup>.99</sup>
                </h2>
                <h6>Yearly </h6>

                {/*
                <div className="main-pricing-content">
                  <p>save 50%</p>

                  <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment(
                        "4",
                        "174.99",
                        "Fantasy Sports & Sports Betting   Yearly"
                      );
                    }}
                  >
                    Buy
                  </button>
                </div>
                */}

                <div className="main-pricing-content">
                  <p class="discoutn-text">save 50%</p>
                  <ul>
                    <li>
                      <p>Advanced Player Data</p>
                    </li>
                    <li>
                      <p>Customized Player Analysis</p>
                    </li>
                    <li>
                      <p>
                        Aggregated weekly , Ranking , Projections , Waiver Wire
                        And Start or Sit{" "}
                      </p>
                    </li>
                    <li>
                      <p>Player Historical Data</p>
                    </li>
                    <li>
                      <p> Customized Team Analysis </p>
                    </li>
                    <li>
                      <p>Advanced Team Data</p>
                    </li>
                    <li>
                      <p>Team Historical Data</p>
                    </li>
                  </ul>

                  {/* <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment(
                        "4",
                        "174.99",
                        "Fantasy Sports & Sports Betting Yearly"
                      );
                    }}
                  >
                    Buy
                  </button> */}
                  {/* <button  className="common-btn" 
                       onClick={()=>history.push({
                       pathname: '/payment',
                       state: { id: "4" },
                    })}>Buy</button> */}

                  <button className="common-btn"
                    onClick={() => {
                      checkLogin("4")
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="pricing-wrap">
                <h2>
                  <span>$</span>28<sup>.99</sup>
                </h2>
                <h6>MONTHLY </h6>
                {/*
                <div className="main-pricing-content">
                  <p></p>

                  <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment(
                        "3",
                        "28.99",
                        "Fantasy Sports & Sports Betting  Monthly"
                      );
                    }}
                  >
                    Buy
                  </button>
                </div>
                */}

                <div className="main-pricing-content">
                  <ul>
                    <li>
                      <p>Advanced Player Data</p>
                    </li>
                    <li>
                      <p>Customized Player Analysis</p>
                    </li>
                    <li>
                      <p>
                        Aggregated weekly , Ranking , Projections , Waiver Wire
                        And Start or Sit{" "}
                      </p>
                    </li>
                    <li>
                      <p>Player Historical Data</p>
                    </li>
                    <li>
                      <p> Customized Team Analysis </p>
                    </li>
                    <li>
                      <p>Advanced Team Data</p>
                    </li>
                    <li>
                      <p>Team Historical Data</p>
                    </li>
                  </ul>
                  {/* <button
                    className="common-btn"
                    onClick={() => {
                      Handlepayment(
                        "3",
                        "28.99",
                        "Fantasy Sports & Sports Betting  Monthly"
                      );
                    }}
                  >
                    Buy
                  </button> */}
                  {/* <button  className="common-btn" 
                       onClick={()=>history.push({
                       pathname: '/payment',
                       state: { id: "3" },
                    })}>Buy</button> */}


                  <button className="common-btn"
                    onClick={() => {
                      checkLogin("3")
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={payment}
        onHide={paymentClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal payment-modal"
      >
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <CheckoutForm subPlan={subPlan} plan_id={plan_id} subAmt={subAmt} />
          </Elements>
        </Modal.Body>
      </Modal>

      <ToastContainer autoClose={2000} />

    </Fragment>
  );
};

export default Pricing;
