import React, { Fragment, useState, useEffect, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { apiInstance } from "./Utility/axios";
import { If } from "react-if";
import { useHistory, Link } from "react-router-dom";
import { has, propOr, pathOr, map } from "ramda";

import { Context } from "./Utility/store";
import { errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";
import { getFormattedNextGame } from "./Utility/NextGame";
// import {
//   getFormattedExclusiveStats,
//   seasonalStatus,
// } from "./Utility/ExclusiveSeasonStatus";
import { getFormattedTrends } from "./Utility/FormattedTrends";

import { ProgessbarsDemo } from "./Progessbars";
// import Checkbox from "./Checkbox";
import { setTimeout } from "core-js";
import Chart from "./Utility/Chart";
import SettingModal from "./SettingModal";
import {
  GetCurrentSeasonStatData,
  GetLastSeasonStatData,
  GetAllSeasonStatData,
  GetTeamPlayerData,
  isEmptyOrNil,
} from "./Utility/actionMethod";

import {
  FacebookShareButton,
  TwitterShareButton,
  GmailShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

const getSettingFullName = new Map([
  ["AR", "air_yards"],
  ["APT", "avg_pocket_time"],
  ["H", "hurries"],
  ["K", "knockdowns"],
  ["OTH", "on_target_throws"],
  ["RAT", "redzone_attempts"],

  ["A", "attempts"],
  ["B", "blocked"],
  ["L", "longest"],
  ["PCT", "Extra Point Percentage"],

  //receiving Exclusive Stats
  ["AY", "air_yards"],
  ["BT", "broken_tackles"],
  ["DP", "dropped_passes"],
  ["T", "targets"],
  ["RT", "redzone_targets"],
  ["YAC", "yards_after_catch"],
  ["YACT", "yards_after_contact"],

  //defense Exclusive stats
  ["BP", "batted_passes"],
  ["H", "hurries"],
  ["K", "knockdowns"],
  ["QBH", "qb_hits"],
  ["S", "safeties"],

  //rushing Exclusive stats
  ["YAC", "yards_after_catch"],
  ["YACO", "yards_after_contact"],
  ["BT", "broken_tackles"],
  ["RZA", "redzone_targets"],
  ["S", "Scrambles"],

  //Hitters Exclusive Stats
  ["BABIP", "BABIP"],
  ["ISO", "ISO"],
  ["BB%", "BB%"],
  ["LD%", "LD%"],
  ["FB%", "FB%"],
  ["GB%", "GB%"],
  ["HR/FB", "HR/FB"],
  ["GBFB", "GBFB"],
  ["K%", "K%"],

  ["BABIP", "BABIP"],
  ["ISO", "ISO"],
  ["BB", "BB%"],
  ["LD", "LD%"],
  ["FB", "FB%"],
  ["GB", "GB%"],
  ["HR/FB", "HR/FB"],
  ["GBFB", "GBFB"],
  ["K%", "K%"],

  //Pitchers Exclusive Stats
  ["K/9", "K/9"],
  ["K/BB", "K/BB"],
  ["IRA", "IRA"],
  ["BQR", "BQR"],
  ["BQR-S", "BQR-S"],
  ["PO", "PO"],
  ["P/IP", "P/IP"],
  ["HR/9", "HR/9"],
  ["H/9", "H/9"],
  ["BB/9", "BB/9"],

  // ========= NBA =========
  ["EFF_EX", "EFF_EX"],
  ["TSP_EX", "TSP_EX"],
  ["FTR_EX", "FTR_EX"],
  ["EFP_EX", "EFP_EX"],
  ["FBP_EX", "FBP_EX"],
  ["PPP_EX", "PPP_EX"],
  ["SCP_EX", "SCP_EX"],
]);

const Image = (props) => {
  const [imageError, setImageError] = useState(false);
  return (
    <img
      className="slick-slide-image"
      src={
        props.img === "" || props.img === undefined
          ? "assets/images/dummy.png"
          : imageError
          ? "assets/images/dummy.png"
          : props.img
      }
      onError={(e) => setImageError(true)}
    />
  );
};

const PlayerComparision = () => {
  let history = useHistory();
  let playerteamInfo;
  let is_login = localStorage.getItem("islogged");
  playerteamInfo = JSON.parse(localStorage.getItem("playerteamInfo"));
  const [playerTeamInfo, setplayerTeamInfo] = useState(playerteamInfo);

  // const [onePlayerTeam, setOnePlayerTeam] = useState(playerTeamInfo[0]);
  // const [twoPlayerTeam, setTwoPlayerTeam] = useState(playerTeamInfo[1]);
  // const[thirdPlayerTeam,setthirdPlayerTeam] = useState(playerTeamInfo[2])
  var nameColor = ["cl-red", "cl-blue", "cl-green"];
  var isTeam = localStorage.getItem("isteam");
  let selected_cat = localStorage.getItem("category_type").toLowerCase();
  let date;
  let time;
  // let compare1 = [];
  // let compare2 = [];
  // let compare3 = [];
  // let seasonTags;
  // let profileType;
  // let abc;
  let type;
  if (isTeam.toLowerCase() === "true") {
    type = "team";
  } else {
    type = "player";
  }

  const [ChartData, setChartData] = useState([]);
  const [state, dispatch] = useContext(Context);

  const pontttt = propOr("0", "total_points", state);

  const [sharingURL, setsharingURL] = useState();
  const [ShareModal, setShareModal] = useState(false);

  const [showSetting, setshowSetting] = useState(false);
  const [loader, setLoader] = useState(true);
  const [playerList, setPlayerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [ShowPlayerList, setShowPlayerList] = useState(false);
  const searchList = [...playerList, ...teamList];

  const [isfirstPlayer, setisfirstPlayer] = useState(false);
  const [issecondPlayer, setissecondPlayer] = useState(false);
  const [isthirdPlayer, setisthirdPlayer] = useState(false);

  const [isthirdTeam, setisthirdTeam] = useState(false);

  const [firstPlayer, setfirstPlayer] = useState([]);
  const [firstPlayerTeam, setfirstPlayerTeam] = useState([]);

  const [secondPlayer, setsecondPlayer] = useState([]);
  const [secondPlayerTeam, setsecondPlayerTeam] = useState([]);

  const [thirdPlayer, setthirdPlayer] = useState([]);
  const [thirdPlayerTeam, setthirdPlayerTeam] = useState([]);

  const [firstTeam, setfirstTeam] = useState([]);
  const [secondTeam, setsecondTeam] = useState([]);
  const [thirdTeam, setthirdTeam] = useState([]);

  const [nextGame, setnextGame] = useState([]);
  const [subscribedPlan, setsubscribedPlan] = useState([]);
  const [showSubData, setshowSubData] = useState(true);
  const [isCopied, setIsCopied] = useState();

  // const [pathOr({},['2','Excslusive'],seasonStatData3)Lastyear,setpathOr({},['2','Excslusive'],seasonStatData3)Lastyear] = useState([]);

  // const [pathOr({},['2','Excslusive'],seasonStatData3),setpathOr({},['2','Excslusive'],seasonStatData3)] = useState([]);

  const [selectednflTeamPos, setselectednflTeamPos] = useState(
    selected_cat === "nfl"
      ? "passing"
      : selected_cat === "nba"
      ? "nba"
      : "hitting"
  );

  const [is2020, setis2020] = useState(false);
  const [is2019, setis2019] = useState(false);
  const [isseason, setisseason] = useState(true);

  const [ProfileData, setProfileData] = useState([]);
  const [oneChartData, setoneChartData] = useState([]);
  const [TwoChartData, setTwoChartData] = useState([]);
  const [ThreeChartData, setThreeChartData] = useState([]);
  let total = 0;

  const [totalArrPoints, settotalArrPoints] = useState([]);
  const [totalPoints, settotalPoints] = useState([]);
  const [CheckComparision, setCheckComparision] = useState(
    type === "team" ? true : false
  );

  let onecompareExclusive = pathOr(
    [],
    ["seasonExclusiveData1demo", "Exclusive", selectednflTeamPos],
    state
  );
  let twocompareExclusive = pathOr(
    [],
    ["seasonExclusiveData1demo", "Exclusive"],
    state
  );

  const hitters = ["3B", "CF", "SS", "2B", "C", "1B", "RF", "LF", "DH", "OF"];
  const pitchers = ["P", "SP", "RP"];
  const qb = ["QB"];
  const rb = ["RB"];
  const wr = ["WR"];
  const te = ["TE"];
  const d = ["D"];
  const k = ["K"];

  const settingBar = JSON.parse(localStorage.getItem("user_setting"));

  const SettingModalHandler = () => {
    setshowSetting(true);
  };
  const HideSettingModal = () => {
    setshowSetting(false);
  };

  const HideShareModal = () => {
    setShareModal(false);
  };

  const hitter = [
    "G",
    "AB",
    "R",
    "H",
    "HR",
    "twoB",
    "threeB",
    "RBI",
    "BB",
    "SO",
    " SB",
    " CS",
    " AVG",
    " OBP",
    " SLG",
    " OPS",
  ];
  const picther = [
    "G",
    "W",
    "L",
    "ERA",
    "SV",
    "SVO",
    "IP",
    "H",
    "R",
    "ER",
    "HR",
    "HB",
    "BB",
    "IBB",
    "SO",
    "WHIP",
  ];
  const NbaPlayer = [
    "GP",
    "MIN",
    "PTS",
    "REB",
    "ASTT",
    "TOV",
    "STL",
    "BLK",
    "FGM",
    "FGA",
    "FGP",
    "THPM",
    "THPA",
    "THPP",
    "FTA",
    "FTP",
    "PF",
    "DDT",
    "TDTH",
  ];
  const NbaTeam = [
    "GP",
    "PTS",
    "REB",
    "ASTT",
    "TOV",
    "STL",
    "BLK",
    "FGM",
    " FGA",
    "FGP",
    "THPM",
    "THPA",
    "THPP",
    "FTA",
    "FTP",
    "PF",
  ];

  const passing = [
    "COMP",
    "PATT",
    "PYDS",
    "PAVG",
    "PTD",
    "INT",
    "SCK",
    "QBR",
    "RATT",
    "RYDS",
    "RAVG",
    "RTD",
    "FUM",
    "LOST",
  ];
  const rushing = [
    "RATT",
    "RYDS",
    "RAVG",
    "LNG",
    "RTD",
    "REC",
    "REYDS",
    "REAVG",
    "RETD",
  ];
  const field_goals = ["ATT", "M", "MI", "PCT", "LNG", "EM", "EPCT"];
  const defense = ["AST", "TOT", "SACK", "SCKYADS", "TFL", "PD", "INT", "FR"];
  const receiving = ["REC", "YDS", "REAVG", "TD", "LNG", "TGT"];

  const NflTeamPassing = [
    "COMP",
    "PATT",
    "PYDS",
    "PAVG",
    "PTD",
    "INT",
    "SCK",
    "QBR",
  ];
  const NflTeamRushing = ["RATT", "RYDS", "RAVG", "LNG", "RTD"];
  const NflReceiving = ["REC", "YDS", "REAVG", "TD", "LNG", "TGT"];
  const HitterExclusive = ["BABIP", "ISO", "BB", "LD", "FB", "GB"];
  const PitchersExclusive = [
    "K9",
    "KBB",
    "IRA",
    "BQR",
    "BQRS",
    "PO",
    "PIP",
    "HR9",
    "HR",
  ];

  const PassingExclusive = ["AR", "APT", "H", "K", "OTH", "RAT"];

  const RushingExclusive = ["BT", "RZA", "YACO", "S", "YAC"];

  const ReceivingExclusive = ["AY", "BT", "DP", "T", "RT", "YAC", "YACT"];

  const DefenseExclusive = ["BP", "H", "K", "QBH", "S"];
  const FieldGoalsExclusive = ["A", "B", "L", "PCT"];
  const NbaExclusive = [
    "EFF_EX",
    "TSP_EX",
    "FTR_EX",
    "EFP_EX",
    "FBP_EX",
    "PPP_EX",
    "SCP_EX",
  ];
  let PlayerTeamList = [];

  // const GetExlusiveStat = (profileType, position, teamPosition, selectGameType) => {
  //     const pitchers = ['P', 'SP', 'RP'];
  //     if (profileType === 'player') {
  //       if (pitchers.includes(position)) return PitchersExclusive;
  //       else return position==='QB' ? PassingExclusive : position==='RB' ? RushingExclusive : position==='WR' ? ReceivingExclusive : position==='TE' ? ReceivingExclusive : position==='D' ? DefenseExclusive  : selectGameType === 'nba' ? NbaExclusive : HitterExclusive;
  //     } else {
  //       if (teamPosition === 'hitting') return HitterExclusive;
  //       else return teamPosition === 'pitching' ? PitchersExclusive : teamPosition==='passing' ? PassingExclusive : teamPosition==='rushing' ? RushingExclusive : teamPosition==='receiving' ? ReceivingExclusive : teamPosition==='receiving' ? ReceivingExclusive : teamPosition==='defense' ? DefenseExclusive : teamPosition === "field_goals" ? FieldGoalsExclusive : NbaExclusive;
  //     }
  //   };

  useEffect(() => {
    if (state.openModal) {
      setshowSetting(false);
    }
  }, [state.openModal]);

  const GetExlusiveStat = (
    profileType,
    position,
    teamPosition,
    selectGameType
  ) => {
    const pitchers = ["P", "SP", "RP"];
    if (profileType === "player") {
      if (pitchers.includes(position)) return PitchersExclusive;
      else
        return position === "QB"
          ? PassingExclusive
          : position === "RB"
          ? RushingExclusive
          : position === "WR"
          ? ReceivingExclusive
          : position === "TE"
          ? ReceivingExclusive
          : position === "D"
          ? DefenseExclusive
          : selectGameType === "nba"
          ? NbaExclusive
          : HitterExclusive;
    } else {
      if (teamPosition === "hitting") return HitterExclusive;
      else
        return teamPosition === "pitching"
          ? PitchersExclusive
          : teamPosition === "passing"
          ? PassingExclusive
          : teamPosition === "rushing"
          ? RushingExclusive
          : teamPosition === "receiving"
          ? ReceivingExclusive
          : teamPosition === "receiving"
          ? ReceivingExclusive
          : teamPosition === "defense"
          ? DefenseExclusive
          : teamPosition === "field_goals"
          ? FieldGoalsExclusive
          : NbaExclusive;
    }
  };

  const getHitterPitcher = (
    profileType,
    position,
    teamPosition,
    selectGameType
  ) => {
    const pitchers = ["P", "SP", "RP"];
    if (profileType === "player") {
      if (pitchers.includes(position)) return picther;
      else
        return position === "QB"
          ? passing
          : position === "RB"
          ? rushing
          : position === "WR"
          ? receiving
          : position === "TE"
          ? receiving
          : position === "D"
          ? defense
          : selectGameType === "nba"
          ? NbaPlayer
          : hitter;
    } else {
      if (teamPosition === "hitting") return hitter;
      else
        return teamPosition === "pitching"
          ? picther
          : teamPosition === "passing"
          ? NflTeamPassing
          : teamPosition === "rushing"
          ? NflTeamRushing
          : teamPosition === "receiving"
          ? NflReceiving
          : teamPosition === "receiving"
          ? NflReceiving
          : teamPosition === "defense"
          ? defense
          : teamPosition === "field_goals"
          ? field_goals
          : NbaTeam;
    }
  };

  let isshare = localStorage.getItem("ishare");
  if (isshare === "true") {
    window.location.reload();
    localStorage.setItem("ishare", false);
  }

  useEffect(async () => {
    PlayerTeamList.push(playerteamInfo);
    const checkComparisoin = checkIfComparsionPossible(PlayerTeamList, type);
    setCheckComparision(checkComparisoin);
    // clearState();

    SeasonalStat();
    NextGame();
    let onePTChartData = JSON.parse(localStorage.getItem("onePT"));
    setoneChartData(
      type === "player" ? onePTChartData.chartArr : onePTChartData
    );
    getChartData();

    var islogin = localStorage.getItem("islogged");
    var user =
      localStorage.getItem("userDetail") !== "" ||
      localStorage.getItem("userDetail") !== null
        ? JSON.parse(localStorage.getItem("userDetail"))
        : {};
    if (islogin === true || islogin === "true") {
      const checkSubscriptionHandler = showExclusiveStatsFunc(
        "true",
        user,
        type,
        selected_cat
      );
      setshowSubData(checkSubscriptionHandler);
    }
  }, []);

  useEffect(() => {
    setsubscribedPlan(state.subscriptionPlan);
  }, [state.subscriptionPlan]);

  useEffect(() => {
    // if (state.my_team_player === true) {
    // setTimeout(() => {
    //   reloadFunction();
    // }, 1000);
    // }
  }, [state.my_team_player]);

  useEffect(() => {
    if (state.teamPlayerSelected) {
      setTimeout(() => {
        // window.location.reload();
        let playerInfo = JSON.parse(localStorage.getItem("playerteamInfo"));

        singlePlayerHandler(playerInfo[2]);
      }, 1000);
    }
  }, [state.teamPlayerSelected]);

  useEffect(async () => {
    if (
      localStorage.getItem("islogged") === false ||
      localStorage.getItem("islogged") === "false" ||
      localStorage.getItem("islogged") === null
    ) {
      dispatch({ type: "USER_SETTING", payload: settingBar });
    }

    setPositionName(
      pathOr(
        selected_cat === "nfl"
          ? "passing"
          : selected_cat === "nba"
          ? "nba"
          : "hitting",
        ["0", "position"],
        playerTeamInfo
      )
    );
  }, []);

  const getPlayerHanlder = (e) => {
    getPlayerList(e.target.value);
    if (e.target.value.length > 3) {
      setShowPlayerList(true);
    } else {
      setShowPlayerList(false);
    }
  };

  const getPlayerList = async (searchVal) => {
    let searchFor =
      pathOr("", ["0", "type"], playerTeamInfo) === "player"
        ? "players"
        : "teams";
    try {
      const response = await apiInstance.get(
        selected_cat + "/" + searchFor + "/list?prefix=" + searchVal
      );
      if (response) {
        setPlayerList(response.data.response);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const singlePlayerHandler = async (data) => {
    let type = has("logo_url", data) ? "team" : "player";
    let id = propOr("", "id", data);
    let pt1id = pathOr("", ["1", "id"], playerTeamInfo);
    let pt2id = pathOr("", ["0", "id"], playerTeamInfo);
    if (id === pt1id || id === pt2id) {
      if (type === "team") {
        errorToast("Team Already selected");
      } else {
        errorToast("Player Already selected");
      }
    } else {
      await GetTeamPlayerData(
        dispatch,
        selected_cat.toLowerCase(),
        type,
        id,
        state
      );
      let playerteamInfo = JSON.parse(localStorage.getItem("playerteamInfo"));
      setplayerTeamInfo(playerteamInfo);
      setShowPlayerList(false);
      // if (playerteamInfo.length === 3) {
      //    window.location.reload();
      // }
    }
  };

  const RemovePalyerHandler = (id) => {
    const newPlayersAndTeam = [];
    playerTeamInfo.filter((playerTeam) => {
      if (playerTeam.id !== id) {
        newPlayersAndTeam.push(playerTeam);
      }
    });

    // const newplayer = playerTeamInfo.splice(newPlayerTeam, 1)
    // console.log( "newPlayerTeam" ,playerTeamInfo);
    localStorage.setItem("playerteamInfo", JSON.stringify(newPlayersAndTeam));
    if (newPlayersAndTeam.length === 1) {
      history.push("/player-profile");
    } else {
      window.location.reload();
    }
  };

  const setPositionName = (position) => {
    if (selected_cat === "nba") {
      setselectednflTeamPos("nba");
    }

    if (selected_cat === "mlb") {
      if (type === "player") {
        if (pitchers.includes(position)) {
          setselectednflTeamPos("pitcher");
        } else {
          setselectednflTeamPos("hitter");
        }
      }
    }
    if (selected_cat === "nfl") {
      if (type === "player") {
        if (position === "QB") {
          setselectednflTeamPos("passing");
        } else if (position === "RB") {
          setselectednflTeamPos("rushing");
        } else if (position === "WR" || position === "TE") {
          setselectednflTeamPos("receiving");
        } else if (position === "D") {
          setselectednflTeamPos("defense");
        } else {
          setselectednflTeamPos("field_goals");
        }
      }
    }
  };

  useEffect(() => {
    NextGame();
    setTimeout(function () {
      if (!isthirdTeam || !isthirdPlayer) {
        SeasonalStat();
        // getChartData();
        //let onePTChartData = JSON.parse(localStorage.getItem("onePT"));
        //setoneChartData(type === "player" ? onePTChartData.chartArr : onePTChartData);
      }
    }, 2000);
  }, [secondPlayer, secondTeam, state.my_team_player]);

  // useEffect(()=>{
  //     getChartData();
  //     let onePTChartData = JSON.parse(localStorage.getItem("onePT"));
  //     setoneChartData(type === "player" ? onePTChartData.chartArr : onePTChartData);
  //     let twoPTChartData = JSON.parse(localStorage.getItem("twoPT"));
  //     setTwoChartData(type === "player" ? twoPTChartData.chartArr : twoPTChartData);
  // },[playerTeamInfo)

  useEffect(() => {
    if (playerTeamInfo.length === 3) {
      NextGame();
      getChartData();
      SeasonalStat();
      let onePTChartData = JSON.parse(localStorage.getItem("onePT"));
      setoneChartData(
        type === "player" ? onePTChartData.chartArr : onePTChartData
      );
      let twoPTChartData = JSON.parse(localStorage.getItem("twoPT"));
      setTwoChartData(
        type === "player" ? twoPTChartData.chartArr : twoPTChartData
      );
    }
  }, [playerTeamInfo]);

  useEffect(() => {
    if (isthirdPlayer === true) {
      setTimeout(() => {
        reloadFunction();
      }, 2000);
    }
    if (isthirdTeam === true) {
      setTimeout(() => {
        reloadFunction();
      }, 2000);
    }
  }, [ChartData, isthirdPlayer]);

  useEffect(() => {
    NextGame();
    //changeYearExclusive();
    setTimeout(function () {
      if (isthirdTeam || isthirdPlayer) {
        SeasonalStat();
        // getChartData();
        // let onePTChartData = JSON.parse(localStorage.getItem("onePT"));

        // setoneChartData(type === "player" ? onePTChartData.chartArr : onePTChartData);
        // let twoPTChartData = JSON.parse(localStorage.getItem("twoPT"));

        // setTwoChartData(type === "player" ? twoPTChartData.chartArr : twoPTChartData);
      }
    }, 2000);
  }, [thirdPlayer, thirdTeam]);

  const checkIfComparsionPossible = (playerTeamList, type) => {
    if (type === "team") return true;
    // const allValuesEqualInArray = arr => arr.every(v => v === arr[0])
    const positions = map((profile) => {
      const position = propOr("", "position", profile);
      if (hitters.includes(position)) return "hitter";
      if (pitchers.includes(position)) return "pitcher";
      if (qb.includes(position)) return "QB";
      if (rb.includes(position)) return "RB";
      if (wr.includes(position)) return "WR";
      if (te.includes(position)) return "TE";
      if (d.includes(position)) return "D";
      if (k.includes(position)) return "K";
    }, playerTeamList[0]);

    const isComparisonPossible = positions.every(
      (val, i, arr) => val === arr[0]
    );
    return isComparisonPossible;
  };

  // NEXT GAME
  const NextGame = async () => {
    let nextGamedata = [];
    //FOR CURRENT YEAR
    playerTeamInfo.map(async (data, index) => {
      let id = data.type === "player" ? data.teamId : data.id;
      const response = await apiInstance.get(
        selected_cat + "/team/" + id + "/schedule/next_game"
      );
      if (response.data.response !== null) {
        let NextGameData = getFormattedNextGame(response, id, selected_cat);
        let schDate = getFormattedNextGame(
          response,
          id,
          selected_cat
        ).scheduledTime;
        const text = schDate;
        const dateTime = text.split(",");
        const Timeyear = dateTime[2].trim().split(" ");
        date = dateTime[0] + dateTime[1] + " " + Timeyear[0];
        time = Timeyear[1] + Timeyear[2];
        let compare_1_data = {
          data: NextGameData,
          matchDate: date,
          matchTime: time,
        };
        nextGamedata.push(compare_1_data);
        setnextGame(nextGamedata);
      }
    });
    // setnextGameData({"compare1":compare1,"compare2":compare2})
  };

  useEffect(() => {
    settotalArrPoints(pontttt);
    propOr([], "PlayerPoint", pontttt).map((val) => {
      total = total + val;
      settotalPoints(total);
    });
  }, [pontttt]);

  useEffect(() => {
    settotalArrPoints(pontttt);
    propOr([], "PlayerPoint", pontttt).map((val) => {
      total = total + val;
      settotalPoints(total);
    });
    SeasonalStat();
  }, [state.user_setting]);

  const TotalcalculatePercentage = (item, total) => {
    const percentage = Math.round((item / total) * 100);
    //console.log("sdasd0",percentage === "NaN" || percentage === NaN ? "-" : percentage)

    if (isNaN(percentage)) {
      return "-";
    } else {
      return percentage + "%";
    }
    //return `${percentage === "NaN" || percentage === NaN ? "-" : percentage}%`;
  };

  // GET TEAM LIST

  const SeasonalStat = async () => {
    //FOR CURRENT YEAR
    playerTeamInfo.map((data, index) => {
      GetCurrentSeasonStatData(
        dispatch,
        selected_cat,
        propOr("", "type", data),
        propOr("", "id", data),
        propOr("", "position", data),
        index + 1,
        state,
        settingBar,
        selectednflTeamPos
      );
    });

    //LAST YEAR
    playerTeamInfo.map((data, index) => {
      GetLastSeasonStatData(
        dispatch,
        selected_cat,
        propOr("", "type", data),
        propOr("", "id", data),
        propOr("", "position", data),
        index + 1,
        state
      );
    });

    //SEASON YEAR
    playerTeamInfo.map((data, index) => {
      GetAllSeasonStatData(
        dispatch,
        selected_cat,
        propOr("", "type", data),
        propOr("", "id", data),
        propOr("", "position", data),
        index + 1,
        state
      );
    });
  };

  //   useEffect(
  //     (data) => {
  //     },
  //     [isTeam ? onecompareExclusive : twocompareExclusive]
  // );

  const changeYear = (year) => {
    if (year === "current") {
      setis2020(true);
      setis2019(false);
      setisseason(false);
    }
    if (year === "last") {
      setis2020(false);
      setis2019(true);
      setisseason(false);
    }
    if (year === "all") {
      setis2020(false);
      setis2019(false);
      setisseason(true);
    }

    if (year === "all") {
      setis2020(false);
      setis2019(false);
      setisseason(true);
    }
  };
  const reloadFunction = () => {
    let reload = localStorage.getItem("isReload");

    if (reload === "false") {
      localStorage.setItem("isReload", true);
      window.location.reload(false);
    }
  };
  const getChartData = async () => {
    let response = "";
    let id;
    let playername;
    let playerPosition;

    if (playerTeamInfo.length === 2) {
      id = pathOr("", ["1", "id"], playerTeamInfo);
      playername = pathOr("", ["1", "name"], playerTeamInfo);
      playerPosition = pathOr("", ["1", "position"], playerTeamInfo);
    }
    if (playerTeamInfo.length === 3) {
      id = pathOr("", ["2", "id"], playerTeamInfo);
      playername = pathOr("", ["2", "name"], playerTeamInfo);
      playerPosition = pathOr("", ["2", "position"], playerTeamInfo);
    }

    if (id !== "") {
      try {
        if (selected_cat === "nfl") {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_statistics?season_type=REG"
          );
        } else if (selected_cat === "mlb") {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_summary?num_recent_games=10"
          );
        } else {
          response = await apiInstance.get(
            selected_cat +
              "/" +
              type +
              "/" +
              id +
              "/game_summary?season_type=REG"
          );
        }
        if (response) {
          const res = response.data.response[0];
          let chartResponse = getFormattedTrends(
            response,
            playerPosition,
            type,
            2,
            playername,
            selected_cat
          );

          var chartArr = [];

          if (type === "player") {
            // if (selected_cat === "nfl") {
            //   const keysTreds = Object.keys(chartResponse.trends);
            //   chartResponse.dates.map((number, index) => {
            //     chartArr.push({
            //       DATE: number,
            //       TRENDS: chartResponse.trends,
            //     });
            //   });
            //   setProfileData(chartResponse.profileNameColor);
            //   var msg = { chartArr: chartArr };
            //   localStorage.setItem("onePT", JSON.stringify(msg));
            //   dispatch({ type: "CHARTDATA", payload: msg });
            //   setChartData(chartArr);
            // }

            if (selected_cat === "nfl") {
              chartResponse.dates.map((number, index) => {
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.trends,
                });
              });
              chartResponse.dates.map((number, index) =>
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.trends,
                })
              );
              setProfileData(chartResponse.profileNameColor);
              var msg = { chartArr: chartArr };
              dispatch({ type: "CHARTDATA", payload: msg });
              if (localStorage.getItem("twoPT") === "") {
                localStorage.setItem("twoPT", JSON.stringify(msg));
              }
              setChartData(chartArr);
            }

            // if (selected_cat === "nfl") {
            //   console.log("chartResponse",chartResponse);
            //   chartResponse.dates.map((number, index) =>
            //     chartArr.push({
            //       DATE: number,
            //       TRENDS : chartResponse.trends
            //     })
            //   );
            //   console.log("chartArr",chartArr);
            //   setProfileData(chartResponse.profileNameColor);
            //   var msg = { chartArr: chartArr };
            //   if (localStorage.getItem("twoPT") === "") {
            //     localStorage.setItem("twoPT", JSON.stringify(msg));
            //   }
            //   dispatch({ type: "CHARTDATA", payload: msg });
            //   setChartData(chartArr);
            // }

            if (selected_cat === "nba") {
              chartResponse.dates.map((number, index) =>
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.trends,
                })
              );

              setProfileData(chartResponse.profileNameColor);
              var msg = { chartArr: chartArr };
              if (localStorage.getItem("twoPT") === "") {
                localStorage.setItem("twoPT", JSON.stringify(msg));
              }
              dispatch({ type: "CHARTDATA", payload: msg });
              setChartData(chartArr);
            }

            if (selected_cat === "mlb") {
              let chartArr = [];

              if (hitters.includes(playerPosition)) {
                chartResponse.dates.map((number, index) =>
                  chartArr.push({
                    DATE: number,
                    TRENDS: chartResponse.trends,
                  })
                );
                setProfileData(chartResponse.profileNameColor);
                var msg = { chartArr: chartArr };
                if (localStorage.getItem("twoPT") === "") {
                  localStorage.setItem("twoPT", JSON.stringify(msg));
                }
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(chartArr);
              }
              if (pitchers.includes(playerPosition)) {
                chartResponse.dates.map((number, index) =>
                  chartArr.push({
                    DATE: number,
                    TRENDS: chartResponse.trends,
                  })
                );

                setProfileData(chartResponse.profileNameColor);
                var msg = { chartArr: chartArr };
                if (localStorage.getItem("twoPT") === "") {
                  localStorage.setItem("twoPT", JSON.stringify(msg));
                }
                dispatch({ type: "CHARTDATA", payload: msg });
                setChartData(chartArr);
              }
            }
          } else {
            if (selected_cat === "nfl") {
              if (chartResponse !== undefined) {
                var chartArrpass = [];
                chartResponse.passing.dates.map((number, index) =>
                  chartArrpass.push({
                    DATE: number,
                    TRENDS: chartResponse.passing.trends,
                  })
                );

                var chartArrrush = [];
                chartResponse.rushing.dates.map((number, index) =>
                  chartArrrush.push({
                    DATE: number,
                    TRENDS: chartResponse.rushing.trends,
                  })
                );

                var chartArrrecei = [];
                chartResponse.receiving.dates.map((number, index) =>
                  chartArrrecei.push({
                    DATE: number,
                    TRENDS: chartResponse.receiving.trends,
                  })
                );

                var chartArrDefense = [];
                chartResponse.defense.dates.map((number, index) =>
                  chartArrDefense.push({
                    DATE: number,
                    TRENDS: chartResponse.defense.trends,
                  })
                );

                var chartArrfield_goals = [];
                chartResponse.field_goals.dates.map((number, index) =>
                  chartArrfield_goals.push({
                    DATE: number,
                    TRENDS: chartResponse.field_goals.trends,
                  })
                );
                setProfileData(chartResponse.profileNameColor);
                var msg = {
                  passing: chartArrpass,
                  rushing: chartArrrush,
                  receiving: chartArrrecei,
                  defense: chartArrDefense,
                  field_goals: chartArrfield_goals,
                };
                if (localStorage.getItem("twoPT") === "") {
                  localStorage.setItem("twoPT", JSON.stringify(msg));
                }

                setChartData(msg);
                dispatch({ type: "CHARTDATA", payload: msg });
              }
            } else if (selected_cat === "mlb") {
              if (chartResponse !== undefined) {
                let hittingArr = [];
                let pitchingArr = [];

                chartResponse.hitting.dates.map((number, index) =>
                  hittingArr.push({
                    DATE: number,
                    TRENDS: chartResponse.hitting.trends,
                  })
                );

                chartResponse.pitching.dates.map((number, index) =>
                  pitchingArr.push({
                    DATE: number,
                    TRENDS: chartResponse.pitching.trends,
                  })
                );

                setProfileData(chartResponse.profileNameColor);
                var msg = { hitting: hittingArr, pitching: pitchingArr };
                dispatch({ type: "CHARTDATA", payload: msg });
                if (localStorage.getItem("twoPT") === "") {
                  localStorage.setItem("twoPT", JSON.stringify(msg));
                }
                setChartData(msg);
              }
            } else {
              chartResponse.nba.dates.map((number, index) =>
                chartArr.push({
                  DATE: number,
                  TRENDS: chartResponse.nba.trends,
                })
              );
              setProfileData(chartResponse.profileNameColor);
              var msg = { nba: chartArr };
              dispatch({ type: "CHARTDATA", payload: msg });
              if (localStorage.getItem("twoPT") === "") {
                localStorage.setItem("twoPT", JSON.stringify(msg));
              }
              setChartData(msg);
            }
          }
        }
        if (!thirdPlayer) {
          localStorage.setItem("twoPT", JSON.stringify(chartArr));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const showExclusiveStatsFunc = (islogin, user, type, selected_cat) => {
    if (user === null && (islogin === "false" || islogin === false)) {
      return true;
    }

    var isTrial = propOr(false, "is_trial", user);
    var subscriptionPackage = propOr(false, "subscribedPlans", user);
    var subscriptionData = propOr(false, "subscriptionData", user);

    if (islogin === "false" || islogin === false) return true;
    if (isTrial) return false;
    else {
      if (!isEmptyOrNil(subscriptionPackage)) {
        let purchasePlan = true;
        map((plan) => {
          if (!plan[1]) {
            if (plan[0] !== "draft-cheat-sheet") {
              if (
                plan[0].includes(selected_cat) &&
                plan[0].includes("yearly")
              ) {
                purchasePlan =
                  !subscriptionData["sports"][
                    plan[0].replace(selected_cat + "-", "")
                  ][type == "player" ? "player_exclusive" : "team_exclusive"];
              } else if (plan[0].includes(selected_cat)) {
                purchasePlan =
                  !subscriptionData["sports"][
                    plan[0].replace(selected_cat + "-", "")
                  ][type == "player" ? "player_exclusive" : "team_exclusive"];
              } else {
                if (purchasePlan) {
                  purchasePlan =
                    !subscriptionData["sports"][plan[0]][
                      type == "player" ? "player_exclusive" : "team_exclusive"
                    ];
                }
              }
            }
          }
        }, subscriptionPackage);
        return purchasePlan;
      } else {
        return !subscriptionData["sports"]["free"][
          type == "player" ? "player_exclusive" : "team_exclusive"
        ];
      }
    }
  };

  const shareUrlHandler = () => {
    // let id1 = pathOr("0",["0","id"],playerTeamInfo);
    // let firstName = pathOr("0",["0","name"],playerTeamInfo);
    // let id2 = pathOr("0",["1","id"],playerTeamInfo);
    // let secondName = pathOr("0",["1","name"],playerTeamInfo);
    // if(playerTeamInfo.length === 3){
    // let id3 = pathOr("0",["2","id"],playerTeamInfo);
    // let thirdName = pathOr("0",["2","name"],playerTeamInfo);
    //   setsharingURL( `192.168.1.52:3000/player-profile2/${id1}/${id2}/${id3}/${selected_cat}/${type}/${firstName}/${secondName}/${thirdName}`);
    // }else{
    //   setsharingURL( `192.168.1.52:3000/player-profile2/${id1}/${id2}/${selected_cat}/${type}/${firstName}/${secondName}`);
    // }
    let api = "http://www.fanalyze.com/profilesharing/";
    if (type === "player") {
      playerTeamInfo.map((player) => {
        api +=
          propOr("0", "id", player) +
          "/" +
          propOr("0", "name", player) +
          "/" +
          propOr("0", "position", player) +
          "/";
      });
    } else {
      playerTeamInfo.map((player) => {
        api +=
          propOr("0", "id", player) +
          "/" +
          propOr("0", "name", player) +
          "/" +
          0 +
          "/";
      });
    }
    api += selected_cat + "/" + type;
    api = api.split(" ").join("");
    setsharingURL(api);
  };

  const UpgradePackageHandler = () => {
    history.push("/pricing");
  };

  const openSigupModal = () => {
    history.push("/login");
    //dispatch({ type: "OPEN_MODAL", payload: true });
  };

  const copyText = () => {
    setIsCopied("URL is copied");
    setTimeout(() => {
      setIsCopied("");
    }, 5000);
  };

  return (
    <Fragment>
      <Fragment>
        {/*<!--=== BREADCRUMB ==== -->*/}
        <section className="brd-cmb p-30 player-comp-breadcrumb">
          <div className="container">
            <div className="breadcrumb-wrapper">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="./">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {type === "player"
                      ? "Players Comparison"
                      : "Team Comparison"}{" "}
                  </li>
                </ol>
              </nav>
              <div className="search-button-wrapper">
                <div className="search-wrapper">
                  <div className="player-search-box">
                    <div className="d-flex align-items-center">
                      {playerTeamInfo.length !== 3 ? (
                        <span>
                          <i className="fas fa-search"></i>
                        </span>
                      ) : (
                        ""
                      )}
                      {playerTeamInfo.length !== 3 ? (
                        <input
                          type="text"
                          name=""
                          placeholder="Search to Compare"
                          onChange={getPlayerHanlder}
                        />
                      ) : (
                        " "
                      )}
                    </div>
                    <div className="sreached-player-block">
                      {playerTeamInfo.map((data, index) => (
                        <span
                          onClick={() =>
                            RemovePalyerHandler(propOr("", "id", data))
                          }
                        >
                          {
                            propOr("", "name", data).split(" ")[
                              propOr("", "name", data).split(" ").length - 1
                            ]
                          }{" "}
                          <i class="far fa-times-circle"></i>
                        </span>
                      ))}

                      <If condition={isfirstPlayer}>
                        <span
                          onClick={() => RemovePalyerHandler(firstPlayer.id)}
                        >
                          {selected_cat === "nba"
                            ? firstPlayer.full_name !== undefined
                              ? firstPlayer.full_name.split(" ")[
                                  firstPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : selected_cat === "mlb"
                            ? firstPlayer.full_name !== undefined
                              ? firstPlayer.full_name.split(" ")[
                                  firstPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : firstPlayer.name !== undefined
                            ? firstPlayer.name.split(" ")[
                                firstPlayer.name.split(" ").length - 1
                              ]
                            : ""}{" "}
                          <i class="far fa-times-circle"></i>
                        </span>
                      </If>

                      <If condition={issecondPlayer}>
                        <span
                          onClick={() => RemovePalyerHandler(secondPlayer.id)}
                        >
                          {" "}
                          {selected_cat === "nba"
                            ? secondPlayer.full_name !== undefined
                              ? secondPlayer.full_name.split(" ")[
                                  secondPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : selected_cat === "mlb"
                            ? secondPlayer.full_name !== undefined
                              ? secondPlayer.full_name.split(" ")[
                                  secondPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : secondPlayer.name !== undefined
                            ? secondPlayer.name.split(" ")[
                                secondPlayer.name.split(" ").length - 1
                              ]
                            : ""}{" "}
                          <i class="far fa-times-circle"></i>
                        </span>
                      </If>
                      <If condition={isthirdPlayer}>
                        <span
                          onClick={() => RemovePalyerHandler(thirdPlayer.id)}
                        >
                          {" "}
                          {selected_cat === "nba"
                            ? thirdPlayer.full_name !== undefined
                              ? thirdPlayer.full_name.split(" ")[
                                  thirdPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : selected_cat === "mlb"
                            ? thirdPlayer.full_name !== undefined
                              ? thirdPlayer.full_name.split(" ")[
                                  thirdPlayer.full_name.split(" ").length - 1
                                ]
                              : ""
                            : thirdPlayer.name !== undefined
                            ? thirdPlayer.name.split(" ")[
                                thirdPlayer.name.split(" ").length - 1
                              ]
                            : ""}{" "}
                          <i class="far fa-times-circle"></i>
                        </span>
                      </If>
                    </div>

                    <If condition={ShowPlayerList}>
                      <ul
                        className={
                          playerList.length === 0
                            ? "search-result h-auto"
                            : "search-result h-400"
                        }
                      >
                        <div className={loader ? "sipnner-wrap" : "d-none"}>
                          <Spinner animation="border" />
                        </div>
                        {searchList.length !== 0 ? (
                          searchList.map((searchitem, index) => (
                            <div>
                              {
                                <li
                                  onClick={() =>
                                    singlePlayerHandler(searchitem)
                                  }
                                  key={index}
                                >
                                  {" "}
                                  <span className="searchListImage">
                                    {" "}
                                    <Image
                                      img={
                                        searchitem.photo_url !== undefined
                                          ? searchitem.photo_url
                                          : searchitem.logo_url
                                      }
                                    />
                                    {/* <img
                            onError={(data)=>console.log(data)}
                              src={
                                searchitem.photo_url !== undefined
                                  ? searchitem.photo_url
                                  : searchitem.logo_url
                              }
                              alt="-"
                            />{" "} */}
                                  </span>{" "}
                                  {searchitem.photo_url
                                    ? (selected_cat === "nfl"
                                        ? searchitem.name.length
                                        : searchitem.full_name.length) === ""
                                      ? "No Such Player or Team Found"
                                      : selected_cat === "nfl"
                                      ? searchitem.name
                                      : searchitem.full_name
                                    : searchitem.market_name}
                                </li>
                              }
                            </div>
                          ))
                        ) : isTeam === "true" ? (
                          <li>No such Team Found </li>
                        ) : (
                          <li>No such Player Found </li>
                        )}
                      </ul>
                    </If>
                  </div>
                </div>
                <div className="compare-btn-wrap">
                  <button onClick={SettingModalHandler}>
                    <img src="assets/images/icons/setting.png" alt="setting" />
                  </button>
                  <button
                    onClick={() => {
                      setShareModal(true);
                      shareUrlHandler();
                    }}
                  >
                    <img src="assets/images/icons/share.png" alt="share" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*<!--=== PLAYER INFO  ==== -->*/}
        <section className="common-section-lg p-50 pb-3 mt-3">
          <div className="container">
            <div className="cmn-shadow-box">
              <div className="player-comparision">
                {playerTeamInfo.map((data, index) => (
                  <Fragment>
                    {index === 1 ? <div className="player-seprator"></div> : ""}
                    {index === 2 ? <div className="player-seprator"></div> : ""}
                    <div className="player-compare-detail">
                      <div className="player-images">
                        <img
                          src={propOr("", "profileImageUrl", data)}
                          className="player"
                          alt="player"
                        />
                      </div>

                      <div className="player_details">
                        <h5 className="title_2">{propOr("", "name", data)}</h5>

                        {type === "player" ? (
                          <p className="team">
                            {" "}
                            {propOr("", "position", data)}{" "}
                            <span>{propOr("", "teamName", data)} </span>
                          </p>
                        ) : (
                          <div className="player-detail-age ">
                            <p className="mb-0">
                              {propOr("", "divisionName", data) === "Conf Rank"
                                ? propOr("", "divisionName", data) +
                                  " " +
                                  propOr("", "rank", data)
                                : propOr("", "divisionName", data)}
                            </p>
                            <p className="mb-0">
                              {propOr("", "win", data)}-
                              {propOr("", "loss", data)}{" "}
                            </p>
                          </div>
                        )}

                        <h6> {propOr("", "status", data)} </h6>
                        {CheckComparision ? (
                          <span className="percent bg-red">
                            {TotalcalculatePercentage(
                              pathOr(
                                "0",
                                ["PlayerPoint", index],
                                totalArrPoints
                              ),
                              totalPoints
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/*<!--=== NEXT GAME   ==== -->*/}
        {nextGame.length !== 0 ? (
          CheckComparision ? (
            pathOr(
              false,
              [type, "next_game", "show_next_game"],
              state.user_setting
            ) === true ? (
              <section>
                <div className="container">
                  <div className="next-game-compare cmn-shadow-box mt-5">
                    <div className="title_2 w-100 d-flex align-items-center justify-content-between">
                      <h3 className="mb-0">next game</h3>
                    </div>
                    <div className="next-team-wrapper">
                      {nextGame.map((game, index) => (
                        <div className="team-outter-wrapper">
                          <div className="teams-wrap">
                            <div className="team">
                              <div className="team-flag-img">
                                <img
                                  src={pathOr(
                                    "",
                                    ["data", "home", "logo_url"],
                                    game
                                  )}
                                  alt=""
                                />
                              </div>
                              <h4 className="title_2">
                                {pathOr("", ["data", "home", "name"], game)}
                              </h4>
                            </div>

                            <div>
                              <h5>@</h5>
                            </div>
                            <div className="team">
                              <div className="team-flag-img">
                                <img
                                  src={pathOr(
                                    "",
                                    ["data", "away", "logo_url"],
                                    game
                                  )}
                                  alt=""
                                />
                              </div>
                              <h4 className="title_2">
                                {pathOr("", ["data", "away", "name"], game)}
                              </h4>
                            </div>
                          </div>
                          <div className="next-game-detail">
                            <div>
                              <span>
                                <i className="fas fa-calendar-day"></i>
                              </span>
                              {propOr("", "matchDate", game)}{" "}
                            </div>
                            <div className="text-end">
                              <span>
                                <i className="far fa-clock"></i>
                              </span>
                              {propOr("", "matchTime", game)}
                            </div>
                            <div>
                              <span>
                                <i className="fas fa-map-marker-alt"></i>
                              </span>{" "}
                              {pathOr("", ["data", "venueCity"], game) +
                                " " +
                                pathOr("", ["data", "venueName"], game)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {isTeam === "true" ? (
          <section className="ranking-table-wrap mt-4">
            <div className="container">
              <div className="cmn-shadow-box py-4">
                {selected_cat === "nfl" ? (
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "passing"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("passing")}
                      >
                        Pass
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "rushing"
                            ? "nav-link active "
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("rushing")}
                      >
                        Rush
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "receiving"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("receiving")}
                      >
                        Rec
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "defense"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("defense")}
                      >
                        Def
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "field_goals"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("field_goals")}
                      >
                        Kick
                      </button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                {selected_cat === "mlb" ? (
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "hitting"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("hitting")}
                      >
                        Hitting
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          selectednflTeamPos === "pitching"
                            ? "nav-link active "
                            : "nav-link"
                        }
                        onClick={() => setselectednflTeamPos("pitching")}
                      >
                        Pitching
                      </button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        {/*<!--=== CHART DATA  ==== -->*/}

        {CheckComparision ? (
          <section className="common-section-lg p-50 mt-3">
            <div className="container">
              {pathOr(
                false,
                [type, "player_trends", "show_player_trends"],
                state.user_setting
              ) === true ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="news-wrap cmn-shadow-box">
                      <div className="player-trend-wrapper">
                        {playerTeamInfo.length === 3 ? (
                          <Chart
                            selectedType={type}
                            selectedGame={selected_cat}
                            data={oneChartData}
                            data2={TwoChartData}
                            data3={ChartData}
                            selectedPos={selectednflTeamPos}
                          ></Chart>
                        ) : (
                          <Chart
                            selectedType={type}
                            selectedGame={selected_cat}
                            data={oneChartData}
                            data2={ChartData}
                            selectedPos={selectednflTeamPos}
                          ></Chart>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        ) : (
          ""
        )}

        {/*<!--=== PLAYER NAME INFO ==== -->*/}
        {CheckComparision ? (
          <section className="player-name-compare-wrapper">
            <div className="container">
              <div className="player-name-box">
                <div className="player-name-compare">
                  {playerTeamInfo.map((data, index) => (
                    <h2
                      key={index}
                      className={"title_2" + " " + nameColor[index]}
                    >
                      {" "}
                      {
                        propOr("", "name", data).split(" ")[
                          propOr("", "name", data).split(" ").length - 1
                        ]
                      }
                    </h2>
                  ))}
                </div>
                <div className="player-name-bar">
                  <div className="first-player"></div>
                  <div className="second-player"></div>
                  {playerteamInfo.length === 3 ? (
                    <div className="third-player"></div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        {/*<!--=== EXCLUSIVE DATA ==== -->*/}
        {CheckComparision ? (
          <section className="ranking-table-wrap mt-5">
            <div className="container">
              <div className="cmn-shadow-box exclusive-data">
                {is_login === "false" ? (
                  <div className="hideExclusiveData">
                    <button className="common-btn" onClick={openSigupModal}>
                      Sign up For free trial
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {is_login === "true" ? (
                  <div
                    className={showSubData ? "hideExclusiveData" : "d-none "}
                  >
                    <button
                      className="common-btn"
                      onClick={UpgradePackageHandler}
                    >
                      Customize Upgrade Now
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <div className="title_2 w-100 d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Exclusive Data</h3>
                  <ul
                    className={
                      is_login === "false" || selected_cat === "mlb"
                        ? "d-none"
                        : "mb-3 nav nav-tabs"
                    }
                    role="tablist"
                  >
                    <li className="nav-item">
                      <button
                        type="button"
                        className={is2020 ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("current")}
                      >
                        2023 AVG
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={is2019 ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("last")}
                      >
                        22-23 AVG
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={isseason ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("all")}
                      >
                        SEASON
                      </button>
                    </li>
                  </ul>
                </div>

                <div className={is_login === "false" ? "d-none" : "mt-3"}>
                  {type === "team"
                    ? pathOr(
                        [],
                        [
                          is2020
                            ? "seasonExclusiveData1demo"
                            : is2019
                            ? "seasonExclusiveData2"
                            : "seasonExclusiveData3",
                          "Exclusive",
                          selectednflTeamPos,
                        ],
                        state
                      ).map((obj) =>
                        state?.user_setting?.[type]?.["exclusive_stats"]?.[
                          selectednflTeamPos === "hitting"
                            ? "hitter"
                            : selectednflTeamPos === "pitching"
                            ? "pitcher"
                            : selectednflTeamPos
                        ][getSettingFullName.get(obj.keys)] === true ? (
                          <ProgessbarsDemo data={obj} />
                        ) : (
                          ""
                        )
                      )
                    : ""}

                  {type === "player"
                    ? pathOr(
                        [],
                        [
                          is2020
                            ? "seasonExclusiveData1demo"
                            : is2019
                            ? "seasonExclusiveData2"
                            : "seasonExclusiveData3",
                          "Exclusive",
                        ],
                        state
                      ).map((obj) =>
                        state?.user_setting?.[type]?.["exclusive_stats"]?.[
                          selectednflTeamPos === "hitting"
                            ? "hitter"
                            : selectednflTeamPos === "pitching"
                            ? "pitcher"
                            : selectednflTeamPos
                        ][getSettingFullName.get(obj.keys)] === true ? (
                          <ProgessbarsDemo data={obj} />
                        ) : (
                          ""
                        )
                      )
                    : ""}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        {/*<!--=== SEASON DATA ==== -->*/}
        {CheckComparision ? (
          <section className="ranking-table-wrap mt-5 mb-5">
            <div className="container">
              <div className="cmn-shadow-box exclusive-data">
                <div className="title_2 w-100 d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">season Data</h3>
                  <ul
                    className={
                      selected_cat === "mlb" ? "d-none" : "mb-3 nav nav-tabs"
                    }
                    role="tablist"
                  >
                    <li className="nav-item">
                      <button
                        type="button"
                        className={is2020 ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("current")}
                      >
                        2023 AVG
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={is2019 ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("last")}
                      >
                        22-23 AVG
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={isseason ? "nav-link active" : "nav-link"}
                        onClick={() => changeYear("all")}
                      >
                        SEASON
                      </button>
                    </li>
                  </ul>
                </div>

                <div classname="mt-3">
                  {type === "team"
                    ? pathOr(
                        [],
                        [
                          is2020
                            ? "seasonExclusiveData1demo"
                            : is2019
                            ? "seasonExclusiveData2"
                            : "seasonExclusiveData3",
                          "Season",
                          selectednflTeamPos,
                        ],
                        state
                      ).map((obj) =>
                        state?.user_setting?.[type]?.["season_stats"]?.[
                          selectednflTeamPos === "hitting"
                            ? "hitter"
                            : selectednflTeamPos === "pitching"
                            ? "pitcher"
                            : selectednflTeamPos
                        ][obj.keys] === true ? (
                          <ProgessbarsDemo data={obj} />
                        ) : (
                          ""
                        )
                      )
                    : ""}

                  {type === "player"
                    ? pathOr(
                        [],
                        [
                          is2020
                            ? "seasonExclusiveData1demo"
                            : is2019
                            ? "seasonExclusiveData2"
                            : "seasonExclusiveData3",
                          "Season",
                        ],
                        state
                      ).map((obj) =>
                        state?.user_setting?.[type]?.["season_stats"]?.[
                          selectednflTeamPos === "hitting"
                            ? "hitter"
                            : selectednflTeamPos === "pitching"
                            ? "pitcher"
                            : selectednflTeamPos
                        ][obj.keys] === true ? (
                          <ProgessbarsDemo data={obj} />
                        ) : (
                          ""
                        )
                      )
                    : ""}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </Fragment>

      <Fragment>
        <ToastContainer />
      </Fragment>
      <Modal
        show={showSetting}
        onHide={HideSettingModal}
        size="xl"
        centered
        keyboard={false}
        className=""
      >
        <Modal.Body>
          <SettingModal showData={showSubData}></SettingModal>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShareModal}
        onHide={HideShareModal}
        size="lg"
        centered
        keyboard={false}
        className="share-modal"
      >
        <Modal.Body>
          <div className="login-detail">
            <h5>Generated URL can be used for sharing Player OR Team detail</h5>
            <div className="share-icons">
              <FacebookShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={50} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={50} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
              <EmailShareButton
                url={sharingURL}
                quote={"Fanalyze"}
                description={"Fanalyze player comparison"}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={50} round />
              </EmailShareButton>
              <button className="copy-link">
                <span
                  onClick={() => {
                    copyText();
                    navigator.clipboard.writeText(sharingURL);
                  }}
                >
                  <img src="assets/images/copy.png" />
                </span>
              </button>
            </div>
            <span className="iscopied">{isCopied}</span>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default PlayerComparision;
